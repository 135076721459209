export function ParseDomains(raw)
{
	let domains = [];
	if(Array.isArray(raw))
	{
	//eslint-disable-next-line
		for(let i in raw)
		{
			domains.push(ParseDomain(raw[i]));
		}
	}
	else
		domains.push(ParseDomain(raw));
	return domains;
}

export function SearchDomain(uuid, domains)
{
	domains.forEach(function(domain){if(domain.uuid === uuid)return domain;});
	return null;
}

export function ParseDomain(raw)
{
	let domain = new Domain();
	domain.uuid = raw.uuid;
	domain.name = raw.name;
	domain.country = raw.country;
	domain.role = raw.role;
	domain.prole = raw.prole;
	domain.user_id = raw.user_id;
	
	return domain;
}

class Domain{

}