
import React, { Component } from 'react';
import {withRouter, Link} from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {getUser} from '_redux/reducers/login';

import {getGroups as groups, getProducts as products} from '_redux/reducers/domain';

import {setHeader} from '_redux/api/appMessage';

import './ProductView.css';
import {GetId, GetScope, IsPortrait} from 'Utils';
import { FindBreadCrumb, GetChildrenInEntry} from 'LambertzHelper'
import { CONST } from '../../Constants';
import { FindEntry, DynamicSort, IsProduct, GetTitle} from '../../LambertzHelper';
import i18n from '../../i18n';
import ProductDetailView from '../ProductDetailView';
import {GetProductDataUrl, GetGroupDataThumbUrl, GetDefaultGroupDataThumbUrl, GetDefaultProductDataUrl} from '_redux/api/_api';



class ProductView extends Component {
	constructor(props) {
		super(props);
		this.productsRef = React.createRef();
		this.state={filter:null}
		this.logout_clicked = this.logout_clicked.bind(this);
		this.sortClicked = this.sortClicked.bind(this);
	}

	sortClicked()
	{
		if(this.state.filter == null)
			this.setState({...this.state, filter:{sort:CONST._asc}})
		else if(this.state.filter.sort === CONST._asc)
			this.setState({...this.state, filter:{sort:CONST._desc}})
		else
			this.setState({...this.state, filter:null})
	}

	logout_clicked(e)
	{
		console.log(e)
		this.props.logout();
	}

	handleClick(uuid, event) {
		console.log(uuid);

	}

	componentDidUpdate()
	{
		if(IsPortrait())
			return;
		if(this.productsRef.current)
		{
			let w = this.productsRef.current.offsetWidth;
			let h = this.productsRef.current.offsetHeight;
			let borderwidth = getComputedStyle(this.productsRef.current).getPropertyValue('--border-width');
			let border = 0;
			if(borderwidth)
				border = parseInt(borderwidth.split("px").join(""));
			let columns = Math.floor(w/170);
			if(columns<2)
				columns = 2;
			if(columns>5)
				columns = 5;

			let itemw = (w / columns - border - .1)
			let itemw_ = (w / columns)
			if(h<Math.ceil(this.entrylength/columns)*itemw_)
			{
				console.log("scroll")
				w -= 20;
				itemw = (w / columns - border - .1)
			}



			this.productsRef.current.style.setProperty('--size', itemw + "px");
		}
	}
	render() {

		let isProductView = GetScope() === "product";
		let productId = GetId();

		if(this.props.groups == null)
			return("");


		let breadCrumb = [];
		if(this.props.groups && isProductView && productId != null)
			FindBreadCrumb(productId, this.props.groups, breadCrumb)

		breadCrumb.push(CONST._empty);


		let entries = [];
		entries = GetChildrenInEntry(productId, this.props.groups);

		let sortIconClass = "";

		if(this.state.filter == null)
			entries.sort(DynamicSort("internal_sort"));
		else
		{
			if(this.state.filter.sort === CONST._asc)
			{
				sortIconClass = " asc";
				entries.sort(DynamicSort("name"));
			}
			else if(this.state.filter.sort === CONST._desc)
			{
				sortIconClass = " desc";
				entries.sort(DynamicSort("-name"));
			}
		}

		let isProduct = false;
		if(entries.length === 1 && IsProduct(entries[0]))
			isProduct = true;

		this.entrylength = entries.length;

		if(isProduct)
		{
			if(IsPortrait())
				this.props.setHeader({title:entries[0].name,subtitle:entries[0].subrow})
			return(
			<div className="ProductView">
				<ProductDetailView data={entries[0]} products={this.props.products}></ProductDetailView>
			</div>)
		}



		return (
      <div className="ProductView">
				<div className="left">
				<div className="filter">
					<span className="label">{i18n.t("product.filter.label")}</span>
					<span className={"ia sort"+sortIconClass} onClick={this.sortClicked}>{i18n.t("product.filter.abc")}</span>
					<span className="ia sort hidden">{i18n.t("product.filter.date")}</span>
					<span className="ia dropdown hidden">{i18n.t("product.filter.brands")}</span>
				</div>

				<div className="breadcrumb">
				{
					breadCrumb.reverse().map((id)=>
						<div key={id}>
							<Link to={CONST.pathes.product + id}>{FindEntry(id, this.props.groups)?FindEntry(id, this.props.groups).name.Get():i18n.t("product.breadcrumb.all")}</Link>
							<span className="divider">&#62;</span>
						</div>
					)
				}
				</div>
				<div className="products" ref={this.productsRef}>
					{entries.filter((e,i)=>{return entries.indexOf(e) == i;}).map((entry,i)=>
					{
						if(entry.thumb == null)
							return(<Link to={CONST.pathes.product + entry.uuid} key={entry.uuid+"_"+i} className="group">
                <img src={GetGroupDataThumbUrl(entry)} alt="" onError={(e)=>{e.target.src!=GetDefaultGroupDataThumbUrl()?e.target.src=GetDefaultGroupDataThumbUrl():null}} onLoad={(e)=>{e.target.parentNode.classList.add("loaded")}}></img>
                <span>{entry.name.Get()}</span></Link>)
						else
							return(
              <Link to={CONST.pathes.product + entry.uuid} key={entry.uuid+"_"+i}>
                 <img src={GetProductDataUrl(entry, entry.thumb_midi)} alt="" onError={(e)=>{e.target.src!=GetDefaultProductDataUrl()?e.target.src=GetDefaultProductDataUrl():null}}  onLoad={(e)=>{e.target.parentNode.classList.add("loaded")}}></img>
                <span>{GetTitle(entry)}</span>
              </Link>);
					})
					}
				</div>
				</div>
				<div className="right">
					<div className="logo bgimg"></div>
				</div>
		  </div>
    );
  }
}

const mapStateToProps = state => ({
	user: getUser(state),
	groups: groups(state),
	products:products(state)
})
const mapDispatchToProps = dispatch => bindActionCreators({setHeader}, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(ProductView));