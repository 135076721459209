import {fetchLogin, fetchLogout, fetchPassResetRequest,fetchPassReset,  fetchRegister, fetchUserUpdate} from './../actions/login';
import {_doRequest, Endpoints} from './_api.js';

export function auth(data) {

	return _doRequest(Endpoints.auth, fetchLogin, data);	
}

export function logout() {
	
	return _doRequest(Endpoints.logout, fetchLogout);	
}

export function login(data) {
	return _doRequest(Endpoints.login, fetchLogin, data);	
}

export function passResetRequest( data) {
	return _doRequest(Endpoints.requestreset, fetchPassResetRequest, data);	
}

export function register( data) {
	return _doRequest(Endpoints.register, fetchRegister, data);	
}

export function setNewPass( data) {
	return _doRequest(Endpoints.reset, fetchPassReset, data);	
}

export function updateUser( data) {
	return _doRequest(Endpoints.user_update, fetchUserUpdate, data);	
}
