import {
	PRESENTATION_GET_PENDING, PRESENTATION_GET_SUCCESS, PRESENTATION_GET_ERROR, 
	PRESENTATION_CREATE_PENDING, PRESENTATION_CREATE_SUCCESS, PRESENTATION_CREATE_ERROR,
	PRESENTATION_DUPLICATE_PENDING, PRESENTATION_DUPLICATE_SUCCESS, PRESENTATION_DUPLICATE_ERROR,
	PRESENTATION_UPDATE_PENDING, PRESENTATION_UPDATE_SUCCESS, PRESENTATION_UPDATE_ERROR,
	PRESENTATION_DELETE_PENDING, PRESENTATION_DELETE_SUCCESS, PRESENTATION_DELETE_ERROR,
	MODAL_PRESENTATION_GET_PENDING, MODAL_PRESENTATION_GET_SUCCESS, MODAL_PRESENTATION_GET_ERROR, 
	PRESENTATION_SETUSER_PENDING, PRESENTATION_SETUSER_SUCCESS, PRESENTATION_SETUSER_ERROR,
	PRESENTATION_SETACTUAL
} from './../actions/presentation';

import {LOGOUT_SUCCESS} from './../actions/login';


import {initialState}  from 'initialState.js';
import { ParsePresentations, ParseModalPresentation, ParsePresentation, UpdatePresentationInList, SearchPresentation, DeletePresentationInList} from '../_models/Presentation';
import { CONST } from '../../Constants';

export function presentationReducer(state = initialState, action) {
	let presentation, presentations, actualPresentation;

	switch(action.type) {
	
	/*#region get modal*/
	case MODAL_PRESENTATION_GET_PENDING: 
	return {
	...state,
	error: null,
	pending: true,		
}
case MODAL_PRESENTATION_GET_SUCCESS:		
	presentation = ParseModalPresentation(action.payload.presentation);
	
	return {
		...state,
		modalPresentation:presentation,
		pending: false,		
}
case MODAL_PRESENTATION_GET_ERROR:					
	return {
		...state,
		pending: false,
		error: action.info,				
}
/*#endregion get*/

	/*#region get*/
		case PRESENTATION_GET_PENDING: 
			return {
			...state,
			error: null,
			pending: true,		
		}
		case PRESENTATION_GET_SUCCESS:		
    
			presentations = ParsePresentations(action.payload.presentations);
			actualPresentation = null;
      //console.log(action.payload, presentations)
			if(state.actualPresentation != null)
			{
				let found = presentations.filter((p)=>p.uuid === state.actualPresentation.uuid);
				if(found!=null && found.length>0)
					actualPresentation = found[0];
			}
			//console.log(presentations)
			//updateLocalstorage(presentations, actualPresentation)
			return {
				...state,
				pending: false,
				synced:true,
				presentations: presentations,
				actualPresentation: actualPresentation,				
		}
		case PRESENTATION_GET_ERROR:					
			return {
				...state,
				pending: false,
				error: action.info,				
		}
	/*#endregion get*/

	/*#region create*/
		case PRESENTATION_CREATE_PENDING: 
			return {
			...state,
			error: null,
			pending: true,		
		}
		case PRESENTATION_CREATE_SUCCESS:		
		  presentation = ParsePresentation(action.payload.presentation);
			presentations = UpdatePresentationInList(presentation, state.presentations);
			//updateLocalstorage(presentations, presentation);
			presentation.justcreated = true;
			return {
				...state,
				pending: false,
				presentations: presentations,
				actualPresentation: presentation			
		}
		case PRESENTATION_CREATE_ERROR:					
			return {
				...state,
				pending: false,
				error: action.info,				
		}		
	/*#endregion setuser*/

	
	/*#region duplicate*/
	case PRESENTATION_DUPLICATE_PENDING: 
	return {
	...state,
	error: null,
	pending: true,		
	}
	case PRESENTATION_DUPLICATE_SUCCESS:		
		presentation = ParsePresentation(action.payload.presentation);
		presentations = UpdatePresentationInList(presentation, state.presentations);
	
	
	
	//updateLocalstorage(presentations, presentation);
	presentation.justcreated = true;
	return {
		...state,
		pending: false,
		actualPresentation: presentation,
		presentations: presentations
	}
	case PRESENTATION_DUPLICATE_ERROR:					
	return {
		...state,
		pending: false,
		error: action.info,				
	}		
	/*#endregion duplicate*/


	/*#region delete*/
		case PRESENTATION_DELETE_PENDING: 
		return {
		...state,
		error: null,
		pending: true,		
		}
		case PRESENTATION_DELETE_SUCCESS:		
			presentation = ParsePresentation(action.payload.presentation);
			presentations = DeletePresentationInList(presentation, state.presentations);
			//updateLocalstorage(presentations, presentation);

			return {
				...state,
				pending: false,
				presentations: presentations,
				actualPresentation: presentation			
		}
		case PRESENTATION_DELETE_ERROR:					
			return {
				...state,
				pending: false,
				error: action.info,				
		}		

	/*#endregion delete*/

	/*#region update*/
		case PRESENTATION_UPDATE_PENDING: 
			return {
			...state,
			error: null,
			pending: true,		
		}
		case PRESENTATION_UPDATE_SUCCESS:		
			presentation = ParsePresentation(action.payload.presentation);
			presentations = UpdatePresentationInList(presentation, state.presentations);
			
			//updateLocalstorage(presentations, presentation);

			return {
				...state,
				pending: false,
				presentations: presentations,
				actualPresentation: presentation			
		}
		case PRESENTATION_UPDATE_ERROR:					
			return {
				...state,
				pending: false,
				error: action.info,				
		}		
	/*#endregion update*/
		
	/*#region setuser*/
	case PRESENTATION_SETUSER_PENDING: 
			return {
			...state,
			error: null,
			pending: true,		
		}
		case PRESENTATION_SETUSER_SUCCESS:		
      
			presentation = ParsePresentation(action.payload.presentation);
			presentations = UpdatePresentationInList(presentation, state.presentations);
			//updateLocalstorage(presentations, actualPresentation);
			return {
				...state,
				pending: false,
				presentations: presentations,
				actualPresentation: presentation			
		}
		case PRESENTATION_SETUSER_ERROR:					
			return {
				...state,
				pending: false,
				error: action.info,				
		}		
		/*#endregion setuser*/

		case PRESENTATION_SETACTUAL:
		{
			
			return {
				...state,
				pending: false,
				actualPresentation: SearchPresentation(action.value, state.presentations)
			}
		}
		case LOGOUT_SUCCESS:
			return {
				...state,
				pending: false,
				presentations:[],
				actualPresentation: null,
				synced:false
		}
		default: 
			return state;
	}
}

function _depr_updateLocalstorage(presentations, actualPresentation)
{
  console.error("updateLocalstorage");
	// eslint-disable-next-line
//	if(localStorage.getItem("essential.settings.offline_allowed") != "true")
//		return;
	
//	localStorage.setItem(CONST._cookies.offline.presentations, presentations!=null?JSON.stringify(presentations):null);
	
//	localStorage.setItem(CONST._cookies.offline.actualPresentation, actualPresentation!=null?JSON.stringify(actualPresentation):null);
}

export const getPresentations = state => state.presentations.presentations;
export const getActualPresentation = state => state.presentations.actualPresentation;
export const isSynced = state => state.presentations.synced;
export const modalPresentation = state => state.presentations.modalPresentation;
