import React, {Component} from 'react';

import i18n from 'i18n';

import { CONST } from '../../Constants';
import {Link} from 'react-router-dom';

import "./CardLogin.css";

class CardLoginSplash extends Component
{
	render()
	{
		return(
			<div className="Login CardLoginSplash">
				<div className="Card-content">
					<div className="btns">
						<Link to={window.pendingPath} className="lmbrtz_btn">{i18n.t("login.splash.guest_btn")}</Link>
						<Link to={CONST.pathes.login} className="lmbrtz_btn">{i18n.t("login.splash.login_btn")}</Link>
					</div>					
				</div>
			</div>
		);
	}
	
}

export default CardLoginSplash;

