import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from 'i18n';

import {login, logout} from '_redux/api/login';
import {addAppMessage} from '_redux/api/appMessage';
import {forceSyncData} from '_redux/api/_app';
import {APP_MESSAGE_TYPE_ERROR} from '_redux/actions/app';

import {getActualDomain as actualDomain} from '_redux/reducers/domain';

import {getUser, getLogin, getLoginError, getLoginPending} from '_redux/reducers/login';

import {GetError} from 'Errors';
import {ValidateEmail} from 'Utils';

import {Link, Redirect} from 'react-router-dom';
import TextInput from './../assets/TextInput';

import "./CardLogin.css";
import { CONST } from '../../Constants';
import { IsPortrait } from '../../Utils';

class CardLogin extends Component
{
	constructor(props) {
		super(props);
	
		this.state = {user:{val:""}, pass:{val:""}, user_err:false, pass_err:false};
		
		this.handleClick = this.handleClick.bind(this);
		this.handleLogoutClick = this.handleLogoutClick.bind(this);
		this.handleRegister = this.handleRegister.bind(this);
		
		this.onSubmit = this.onSubmit.bind(this);
	}

	handleLogoutClick(event) {
		event.preventDefault();
		this.props.logout();
	}
	
	handleRegister()
	{
		this.setState({...this.state, redirect:'/login/register'})	
	}

	handleClick() {
	
		var errors = false;
		let state = {...this.state};
		
		if(!ValidateEmail(this.state.user.val))
		{
			errors = true;
			state = {...state, user_err:true, error:true};			
		}
		else
			state = {...state, user_err:false};			

		if(this.state.pass.val.length<4)
		{
			errors = true;
			state = {...state, pass_err:true, error:true};
		}
		else
			state ={...state, pass_err:false};
		
		if(!errors)		
		{
			this.props.getLogin({"email":this.state.user.val, "password":this.state.pass.val});
		}
		
		this.setState(state);
  }

	onSubmit(event){
		event.preventDefault();
		this.handleClick();
	}

	componentDidMount()
	{
		this.componentDidUpdate();
	}
	componentDidUpdate()
	{
		if(this.state.redirect)
			this.setState({...this.state, redirect:null})	

		if(this.state.user_err)
		{
			let err = GetError({id:"app001"});
			this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);
			return;
		}
		else if(this.state.pass_err)
		{
			let err = GetError({id:"app003a"});
			this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);
			return;
		}


		const {result, error, pending} = this.props;
		if(result==null && error == null && pending == null)
		{
		
		}	
		else if(!pending)
		{
			if(error != null && this.handledError !== error)
			{		
      

				let err = GetError(error.info);
        if(!window.loggedout)
				  this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);
				this.handledError = error;
			}			
		}		
	}

	render()
	{		
		if(this.state.redirect)
			return(<Redirect to={this.state.redirect}></Redirect>)
				
		if(this.props.actualDomain!=null && this.props.actualDomain.user_id != null && this.props.result && !window.loggedout)
		{			
      if(this.props.actualDomain==null && this.lastforceSync != window.pendingPath+this.props.result){
        this.lastforceSync = window.pendingPath+this.props.result
			  this.props.forceSyncData();
      }
      return(<Redirect to={window.pendingPath}></Redirect>)	
		}
		else
		{
			//localStorage.setItem(CONST._cookies.essential.loggedout, "false")
      window.loggedout = false;
		return(
			<div className="Login">
				<div className="Card-title">
					<span>{i18n.t("login.title")}</span>
				</div>
				<div className="Card-content">
					<form onSubmit={this.onSubmit}>
				
					<TextInput label={i18n.t("login.name.label")} placeholder={i18n.t("login.name.placeholder")} type="text" model={this.state.user} editable={true} error={this.state.user_err}></TextInput>
					<TextInput label={i18n.t("login.pass.label")} placeholder={i18n.t("login.pass.placeholder")} type="password" model={this.state.pass} editable={true}  error={this.state.pass_err}></TextInput>
					
					<div className="btns">
						<div onClick={this.handleClick} className="login_btn lmbrtz_btn">{i18n.t("login.login_btn")}</div>
						{IsPortrait()?<Link to={CONST.pathes.home} className="lmbrtz_btn">{i18n.t("login.splash.guest_btn")}</Link>:null}
						<span  className="link" dangerouslySetInnerHTML={{ __html:i18n.t("login.pass_forgot.link").split("[#link]").join("#/login/pass_forgot")}}></span>
						<span>{i18n.t("login.register.text")}</span>
						<br/>
						<div onClick={this.handleRegister} className="register_btn lmbrtz_btn red">{i18n.t("login.register.link")}</div>
					</div>
					<input type="submit" className="hidden"></input>
					</form>
				</div>
			</div>
		);
		}
	}
}

const mapStateToProps = state => ({
	user: getUser(state),	
  actualDomain:actualDomain(state),
	result: getLogin(state),
	error: getLoginError(state),
	pending: getLoginPending(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({getLogin: login, logout:logout,addAppMessage, forceSyncData}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CardLogin);

