import {fetchDomains, updateUser as _updateUser, fetchLoadUsers, deleteUser as _deleteUser} from './../actions/domain';
import {_doRequest, Endpoints} from './_api.js';





export function get(data) {
	
	return _doRequest(Endpoints.domain_get, fetchDomains, data);	
}

export function updateUser(data) {
	return _doRequest(Endpoints.domain_setUserRole, _updateUser, data);	
}
export function deleteUser(data) {
	return _doRequest(Endpoints.domain_deleteUser, _deleteUser, data);	
}
export function loadUsers(data) {
	return _doRequest(Endpoints.domain_loadUsers, fetchLoadUsers, data);	
}
