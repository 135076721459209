import {fetchGetPresentations, fetchCreatePresentation, fetchUpdatePresentation, fetchGetModalPresentation, fetchDeletePresentation, fetchSetPresentationUser, setActualPresentationAction, fetchDuplicatePresentation} from './../actions/presentation';
import {_doRequest, Endpoints} from './_api.js';

export function get(data) {
	return _doRequest(Endpoints.presentation_get, fetchGetPresentations, data);	
}

export function getModal(data) {
	return _doRequest(Endpoints.presentation_get_modal, fetchGetModalPresentation, data);	
}



export function create(data) {
	return _doRequest(Endpoints.presentation_create, fetchCreatePresentation, data);	
}
export function duplicate(data) {
	return _doRequest(Endpoints.presentation_duplicate, fetchDuplicatePresentation, data);	
}

export function update(data) {
	return _doRequest(Endpoints.presentation_update, fetchUpdatePresentation, data);	
}

export function del(data) {
	return _doRequest(Endpoints.presentation_delete, fetchDeletePresentation, data);	
}

export function setUser(data) {
  
	return _doRequest(Endpoints.presentation_setuser, fetchSetPresentationUser, data);	
}

export function setActual(uuid) {
	return dispatch => {dispatch(setActualPresentationAction(uuid));};	
}
