import {
	AUTH_PENDING, AUTH_ERROR, AUTH_SUCCESS, 
	LOGIN_PENDING, LOGIN_SUCCESS, LOGIN_ERROR, 
	LOGOUT_PENDING, LOGOUT_SUCCESS, LOGOUT_ERROR, 
	PASS_RESET_REQUEST_SUCCESS, PASS_RESET_REQUEST_ERROR, PASS_RESET_REQUEST_PENDING, 
	PASS_RESET_SUCCESS, PASS_RESET_ERROR, PASS_RESET_PENDING,
	REGISTER_SUCCESS, REGISTER_ERROR, REGISTER_PENDING,
	USER_UPDATE_SUCCESS, USER_UPDATE_ERROR, USER_UPDATE_PENDING
} from './../actions/login';

import {initialState}  from 'initialState.js';

export function loginReducer(state = initialState, action) {
	
	  switch(action.type) {
			
			case LOGOUT_PENDING: 
			  	return {
					...state,
					error: null,
					pending: true
        	}
				case LOGOUT_SUCCESS:		
          return {
						...initialState,
            pending: false,
						token: "",
						loggedOut: true,
						user: null,						
        	}
				case LOGOUT_ERROR:	
				
          return {
            ...state,
            pending: false,
						error: action.info,
						token: "",
						loggedOut: true,
						user: null
					}
			case AUTH_PENDING: 
			  	return {
					...state,
					error: null,
					pending: true,
					authpending:true,
				/*	pendingLocation:action.payload.pendingLocation*/
        	}
				case AUTH_SUCCESS:							
//				
					let token = state.token;
					if( action.payload.token != null)
						token = action.payload.token;

					
          return {
						...state,
						pending: false,
						authpending:false,
            token: token,
						user: action.payload.user,			
        	}
        case AUTH_ERROR:					
          return {
            ...state,
						pending: false,
						authpending:false,
						error: action.info,
						token: "",
						loggedOut: true,
						user: null,	
						registerinfo: null,
						registererror: null,
						registerpending: false
					}
				case LOGIN_PENDING: 
			  	return {
					...state,
					error: null,
					pending: true
        	}
				case LOGIN_SUCCESS:	
					let _token = state.token;
					if( action.payload.token != null)
						_token = action.payload.token;
				
			    return {
						...state,
            pending: false,
						token: _token,
						user: action.payload.user,
					
        	}
        case LOGIN_ERROR:					
          return {
            ...state,
            pending: false,
            error: action.info
					}

					case USER_UPDATE_PENDING: 
			  	return {
					...state,
					error: null,
					pending: true
        	}
				case USER_UPDATE_SUCCESS:	
			    return {
						...state,
            pending: false,
						//token: action.payload.token,
						user: action.payload.user,
					
        	}
        case USER_UPDATE_ERROR:					
          return {
            ...state,
            pending: false,
            error: action.info
					}
				case PASS_RESET_REQUEST_SUCCESS:
					return{
						...state,
						passresetrequestpending:false,
						passresetrequestinfo: action.info
					}
				case PASS_RESET_REQUEST_ERROR:
					return{
						...state,
						passresetrequestpending:false,
						passresetrequesterror: action.info
					}
				case PASS_RESET_REQUEST_PENDING:
					return{
						...state,
						passresetrequesterror: null,
						passresetrequestpending: true
					}
				case PASS_RESET_SUCCESS:
					return{
						...state,
						passresetpending:false,
						passresetinfo: action.info
				}
				case PASS_RESET_ERROR:
				return{
					...state,
					passresetpending:false,
					passreseterror: action.info
				}
				case PASS_RESET_PENDING:
				return{
					...state,
					passreseterror: null,
					passresetpending: true
				}
			
				case REGISTER_SUCCESS:
					return{
						...state,
						registerpending:false,
						registerinfo: action.info						
				}
				case REGISTER_ERROR:
					return{
						...state,
						registerpending:false,
						registererror: action.info
					}
				case REGISTER_PENDING:
					return{
						...state,
						registererror: null,
						registerpending: true
					}
			
        default: 
           return state;
    }
}

//export const getPendingLocation = state => state.login.pendingLocation;
export const getLogin = state => state.login.token;
export const getLogout = state => state.login.loggedOut;

export const getUser = state => state.login.user;
export const getAuth = state => state.login.token;
export const getAuthPending = state => state.login.authpending;
export const getLoginPending = state => state.login.pending;
export const getLoginError = state => state.login.error;
export const getPassResetRequestDone = state => state.login.passresetrequestinfo;
export const getPassResetRequestError = state => state.login.passresetrequesterror;
export const getPassResetRequestPending = state => state.login.passresetrequestpending;

export const getPassResetDone = state => state.login.passresetinfo;
export const getPassResetError = state => state.login.passreseterror;
export const getPassResetPending = state => state.login.passresetpending;

export const getRegisterDone = state => state.login.registerinfo;
export const getRegisterError = state => state.login.registererror;
export const getRegisterPending = state => state.login.registerpending;
