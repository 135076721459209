
import { Component } from 'react';
import {withRouter} from 'react-router-dom';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import {getUser, getAuthPending, getLoginError} from './_redux/reducers/login';

import {get as getPresentations} from '_redux/api/presentation';
import {auth} from '_redux/api/login';
import {_getToken} from '_redux/api/_api';
import {getDomains as domains, getActualDomain as actualDomain, getProducts as products, isSynced as isDomainSynced, areProductsSynced, GetVersion, IsLocalLoaded} from './_redux/reducers/domain';

import {get as getDomains} from './_redux/api/domain';
import {get as getProducts} from './_redux/api/product';
import {loadLocalData as LoadLocalData} from './_redux/api/_app';
import { ParseProducts } from '_redux/_models/Product';
import i18n from 'i18n';
import { withTranslation } from 'react-i18next';


class AppData extends Component {
	constructor(props) {
		super(props);
		this.syncStartedAfterLoginChanged = false;
    this.recheckProducts = this.recheckProducts.bind(this)
   /* let s = JSON.parse(localStorage.getItem("tmp_products"));
  
		let s1 = ParseProducts(s);
*/

		//localStorage.setItem("essential.settings.offline_allowed", "true");
		//localStorage.setItem("essential.settings.comfort_allowed", "true");
		
		this.pending = {done:'done', domain:'domain', products:'products', auth:'auth'}
	}

  componentDidMount()
  {
    //setInterval(this.recheckProducts, 60000);
    window.removeEventListener("focus", this.recheckProducts);
    window.addEventListener("focus", this.recheckProducts);
  }
  componentWillUnmount()
  {
    window.removeEventListener("focus", this.recheckProducts);
  }

  recheckProducts()
  {
    if(this.lastRecheck && new Date().getTime()-this.lastRecheck<1*1000)
      return;
    this.lastRecheck = new Date().getTime();
   
    if(this.props.actualDomain)
      this.props.getProducts({"domain_id":this.props.actualDomain.uuid, version:this.props.dataVersion.version,  version_type:this.props.dataVersion.version_type});
  }

  render() {
        
      //getResource("en", "translation", "new_products_group_name")) 
		//localStorage.setItem("pendingPath", this.props.location.pathname);
		/*if(!this.props.localLoaded)
		{
			if(!this.localLoading)
			{
				this.props.LoadLocalData();
				this.localLoading = true;
			}
		}
		else if(this.props.localLoaded)*/
		{
		/*	let syncDataAfterLoginChanged = false;
			if((this.props.user && (this.props.actualDomain==null || this.props.actualDomain.user_id !== this.props.user.uuid))
				|| (!this.props.user && (this.props.actualDomain!=null && this.props.actualDomain.user_id != null)))
			{
				syncDataAfterLoginChanged = true;		
			}
			if(syncDataAfterLoginChanged)
			{

			}*/
			
      let syncDataAfterLoginChanged = false;
      //console.log(this.props.actualDomain.user_id, this.props.user)
			if((this.props.user && (this.props.actualDomain==null || this.props.actualDomain.user_id !== this.props.user.uuid))
				|| (!this.props.user && (this.props.actualDomain!=null && this.props.actualDomain.user_id != null)) && !this.syncStartedAfterLoginChanged)
			{
				syncDataAfterLoginChanged = true;	
        this.syncStartedAfterLoginChanged = true;	
			}
			if(syncDataAfterLoginChanged)
			{

			}

      //console.log(syncDataAfterLoginChanged, this.props.actualDomain, this.props.user)
			if(!this.authChecked)
			{
				if(_getToken()==null || _getToken().length<10){
					this.authChecked = true;
				}
				else{
					if(this.pendingRequest !== this.pending.auth)
					{
						this.pendingRequest = this.pending.auth;
            
            this.props.auth();
					}
					else if(this.pendingRequest === this.pending.auth)
					{
						//if(this.props.user)
						this.authChecked = true;
					}
				}
			}
			if(this.authChecked)	
			{
        
      //  console.log(this.props.isDomainSynced, syncDataAfterLoginChanged)
				if(!this.props.isDomainSynced || syncDataAfterLoginChanged)
				{
					if(this.pendingRequest !== this.pending.domain)
					{
        //   console.log("getDomains")
						this.pendingRequest = this.pending.domain;
						//let s = this.props.user;//compoennt update
						//console.log(s)
            this.syncNotStarted = true;
						this.props.getDomains(this.props.user);	
					}
				}
				if(this.props.isDomainSynced)
				{
					if(this.pendingRequest === this.pending.domain)
					{
						if(!this.props.areProductsSynced && this.pendingRequest !== this.pending.products)
						{
							this.pendingRequest = this.pending.products;									
							this.props.getProducts({"domain_id":this.props.actualDomain.uuid, version:this.props.dataVersion.version+new Date().getTime(),  version_type:this.props.dataVersion.version_type});	
						}						
					}
					else if(	this.pendingRequest === this.pending.products)
					{
						if(this.props.areProductsSynced)
						{
							this.pendingRequest = this.pending.done;
							
							if(_getToken()!=null && _getToken().length>10)
								this.props.getPresentations({"domain_id":this.props.actualDomain.uuid})
						}
					}
				}
			}		
    }
	//	console.log(this.pendingRequest)
		return(null);
  }
}

const mapStateToProps = state => ({
	user: getUser(state),
	domains:domains(state),
	actualDomain:actualDomain(state),
	products: products(state),
	isDomainSynced:isDomainSynced(state),
	areProductsSynced:areProductsSynced(state),
	authPending:getAuthPending(state),
	authError:getLoginError(state),
	dataVersion:GetVersion(state)/*,
	localLoaded: IsLocalLoaded(state)*/
})
const mapDispatchToProps = dispatch => bindActionCreators({auth, getDomains, getProducts, /*LoadLocalData, */getPresentations}, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(withRouter(AppData)));