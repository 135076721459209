import {appMessageAdd, appMessageRemove, setHeaderAction, alertMessageHide, alertMessageShow} from './../actions/app';

export function addAppMessage(title, text, type) {
 return dispatch => {	 	
		dispatch(appMessageAdd(title, text, type));
	};
}

export function removeAppMessage(key) {	
	return dispatch => {	 	
		dispatch(appMessageRemove(key));
	 };
 }


 export function hideAlertMessage() {	
	return dispatch => {	 	
		dispatch(alertMessageHide());
	 };
 }
 export function showAlertMessage(msg) {	
	return dispatch => {	 	
		dispatch(alertMessageShow(msg));
	 };
 }


 
export function setHeader(data) {
	return dispatch => {	 	
		 dispatch(setHeaderAction(data));
	 };
 }
 
 


 