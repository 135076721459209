import React, {Component} from 'react';

import "./ContentInfo.css";

import i18n from 'i18n';
import { Link } from 'react-router-dom';

import { CONST } from '../../../Constants';
import {GetProductDataUrl} from '_redux/api/_api';
import {GetPackages, ParsePercent, GetTitle, PrepareEanVkeForOutput, GetTitleWithPackage} from 'LambertzHelper';
import {IsPortrait} from 'Utils';
import QrCodeImage from 'ui/QrCodeImage';
class ContentInfo extends Component
{
	constructor(props)
	{
		super(props);

		this.state = {openedRow:0}
	}
	getBadges(item)
	{
		return(
			<div className="badges">
				{item.organic_certification?.Get()?<div className="organic_certification">{item.organic_certification.Get()}</div>:null}
			{item.bio.Get()?<img src={"/images/icon_bio.png"} alt=""></img>:null}
			{item.vegan.Get()?<img src={"/images/icon_vegan.png"} alt=""></img>:null}
			{item.fairtrade.Get()?<img src={"/images/icon_fairtrade.png"} alt=""></img>:null}

			{item.geo.Get().toUpperCase().indexOf("AACHENER")>-1?<img src={"/images/icon_aacher_printen.png"} alt=""></img>:null}
			{item.geo.Get().toUpperCase().indexOf("NÜRNBERGER")>-1?<img src={"/images/icon_neurnberger_lebkuchen.png"} alt=""></img>:null}
		</div>
		)
	}

	arClicked(i)
	{

		if(this.state.openedRow == i)
			this.setState({openedRow:-1});
		else
			this.setState({openedRow:i});
	}

	componentDidUpdate()
	{
		if(this.lastItem != this.props.model)
		{
			this.lastItem = this.props.model;
			if(this.state.openedRow != -1)
				this.setState({openedRow:-1});
		}
		window.scrollTo(0, 0);
	}
	render()
	{
		let item = this.props.model;
		//let packages = [item].concat(item.subproducts)
    let packages = GetPackages(item.uuid);

		let title = GetTitle(item);
	//console.log(item)
		if(IsPortrait())
		{
			return(
				<div className="ContentInfo">
					<div className="l">
					<div className="part">
						{item.list && item.list.Get().length>0?
						<>
							<div className="list">
								{item.list.Get().split("\n").map((d, index) => <div key={index + d}>{d}</div>)}
							</div>
							<div className="hr"></div>
						</>
						:null}
						</div>

						<div className="ingredients">
						{
							item.ingridient_keys.Get().length>1?
								item.ingridient_keys.Get().split("\n").map((d, index) => <p key={index + d}><span className="lab">{d}</span><span className="val">{ParsePercent(item.ingridient_values.Get().split("\n")[index])}</span></p>)
							:
								null
						}
						</div>
						<div className="packages_list_mobile">
						{
							packages.map((item, i)=>
								<div className= {"package_mobile"+(this.state.openedRow==i?" opened":"")} key={i}>
									<div className={"dropdown_ar bgimg" +(packages.length<=0?" hidden":"")} onClick={()=>{this.arClicked(i)}}></div>
									<div className="package_row package_title">
										<div className="package_label">{i18n.t("product.info.packages_table.head.name")}</div>
										<div className="package_value">{GetTitleWithPackage(item)}</div>
									</div>
									<div className="collapsable">
										<div className="package_row">
											<div className="package_label">{i18n.t("product.info.packages_table.head.articleNo")}</div>
											<div className="package_value">{item.product_id.Get()}</div>
										</div>

										<div className="package_row">
											<div className="package_label">{i18n.t("product.info.packages_table.head.package")}</div>
											<div className="package_value">{item.package?.Get()}</div>
										</div>
										<div className="package_row">
											<div className="package_label">{i18n.t("product.info.packages_table.head.dimensions")}</div>
											<div className="package_value">{item.package_dimensions?.Get()}</div>
										</div>
										<div className="package_row">
											<div className="package_label">{i18n.t("product.info.packages_table.head.vse_amount")}</div>
											<div className="package_value">{item.vse_amount?.Get()}</div>
										</div>
										<div className="package_row">
											<div className="package_label">{i18n.t("product.info.packages_table.head.vse_pallet")}</div>
											<div className="package_value">{item.vse_pallet?.Get()}</div>
										</div>
										<div className="package_row">
											<div className="package_label">{i18n.t("product.info.packages_table.head.vse_package")}</div>
											<div className="package_value">{item.vse_package?.Get()}</div>
										</div>
										<div className="package_row">
											<div className="package_label">{i18n.t("product.info.packages_table.head.vse_dimensions")}</div>
											<div className="package_value">{item.vse_dimensions?.Get()}</div>
										</div>
										<div className="package_row">
											<div className="package_label">{i18n.t("product.info.packages_table.head.vse_ean")}</div>
											<div className="package_value">{PrepareEanVkeForOutput(item.ean)}</div>
										</div>
										<div className="package_row">
											<div className="package_label">{i18n.t("product.info.packages_table.head.ean")}</div>
											<div className="package_value">{item.vse_ean.Get()}</div>
										</div>
									</div>

								</div>
							)
						}
						</div>

					</div>
				</div>
			);
		}


		return(
			<div className="ContentInfo">

				<div className="l">

				<div className="head">

					<div>
						<h1>{title}</h1>
						<h2>{this.props.model.subrow.Get()}</h2>
						<h3>{i18n.t("product.info.article_no.label") + CONST._space+ this.props.model.product_id.Get()}</h3>
					</div>
					<div className="qrArea">
						{this.props.qrcodeLink!=null?
							<>
              <QrCodeImage url={this.props.qrcodeLink} />
							</>
							:""
						}
					</div>

				</div>

				<div className="part">
				{item.list && item.list.Get().length>0?
				<>
					<div className="hr"></div>
					<div className="flex_col">
						<div className="list">
							{item.list.Get().split("\n").map((d, index) => <div key={index + d}>{d}</div>)}
						</div>
						{this.getBadges(item)}

					</div>
					<div className="hr"></div>
				</>
				:null}
				</div>
				<div className="part split">
					<img className="logo" alt="" src={GetProductDataUrl(this.props.model, item.logo_img)}></img>
					<div className="ingredients">
					{
					item.ingridient_keys.Get().length>1?
						item.ingridient_keys.Get().split("\n").map((d, index) => <p key={index + d}><span className="lab">{d}</span><span className="val">{ParsePercent(item.ingridient_values.Get().split("\n")[index])}</span></p>)
					:
						null
					}
					</div>
				</div>
			<div className="part">
					<table className="packages">
						<thead>
						<tr>
							<th>{i18n.t("product.info.packages_table.head.name")}</th>
							<th>{i18n.t("product.info.packages_table.head.articleNo")}</th>
							<th>{i18n.t("product.info.packages_table.head.package")}</th>
							<th>{i18n.t("product.info.packages_table.head.dimensions")}</th>
							<th>{i18n.t("product.info.packages_table.head.vse_amount")}</th>
							<th>{i18n.t("product.info.packages_table.head.vse_pallet")}</th>
							<th>{i18n.t("product.info.packages_table.head.vse_package")}</th>
							<th>{i18n.t("product.info.packages_table.head.vse_dimensions")}</th>
							<th>{i18n.t("product.info.packages_table.head.vse_ean")}</th>
							<th>{i18n.t("product.info.packages_table.head.ean")}</th>
						</tr>
						</thead>
						<tbody>
						{
							packages.map((item, i)=>
							<tr key={i}>
								{
									true || item.is_copy || item.uuid === this.props.model.uuid?
									<td>{GetTitle(item)}</td>
									:
									<td><Link to={CONST.pathes.product+item.uuid+CONST._slash+CONST._info}>{GetTitle(item)}</Link></td>
								}

								<td>{item.product_id.Get()}</td>
								<td>{item.package && item.package.Get()}</td>
								<td>{item.package_dimensions && item.package_dimensions.Get()}</td>

								<td>{item.vse_amount.Get()}</td>
								<td>{item.vse_pallet.Get()}</td>

								<td>{item.vse_package && item.vse_package.Get()}</td>
								<td>{item.vse_dimensions && item.vse_dimensions.Get()}</td>
								<td>{item.ean && PrepareEanVkeForOutput(item.ean.Get())}</td>
								<td>{item.vse_ean.Get()}</td>
							</tr>
							)}

						</tbody>
					</table>
				</div>

				</div>
				<div className="r bgimg"><img src={GetProductDataUrl(this.props.model, item.info_img, 1024)} alt={item.name.Get?item.name.Get():item.name} /></div>

			</div>
		);
	}
}

export default ContentInfo;