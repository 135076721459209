import { CONST } from "../../Constants";
import { CreateLanguageString } from "./LanguageString";

export function ParseProducts(raw)
{

	let products = [];
	if(Array.isArray(raw))
	{
		//eslint-disable-next-line
		for(let i in raw)
		{
			products.push(ParseProduct(raw[i]));
		}
	}
	else if(raw != null)
		products.push(ParseProduct(raw));
	return products;
}

export function SearchProduct(uuid, products)
{
	products.forEach(function(product){if(product.uuid === uuid)return product;});
}


export function HasPrices(product)
{
	if(product.delivery_date.Get())
		return true;
	if(product.price_marginal_costs.Get())
		return true;
	if(product.price_out.Get())
		return true;
	if(product.price_contribution_margin.Get())
		return true;
	if(product.price_evp.Get())
		return true;
	if(product.price_trading_margin.Get())
		return true;
	return false;

}
export function ParseProduct(raw)
{

	let product = new Product();
  product.uuid = raw.uuid;
  product.is_public = raw.is_public;

  product.name = CreateLanguageString(raw.name);
  product.internal_id = CreateLanguageString(raw.internal_id);

	product.is_copy = false;//product.internal_id.slice(-1) === '-';
	product.weight = CreateLanguageString(raw.public_data.weight);
	product.subrow = CreateLanguageString(raw.public_data.subrow);

	product.product_id = CreateLanguageString(raw.public_data.product_id);
	product.ingridient_keys = CreateLanguageString(raw.public_data.ingridient_keys, null, 'array');

	product.ingridient_values = CreateLanguageString(raw.public_data.ingridient_values,null,  'array');
	product.name_long = CreateLanguageString(raw.public_data.name_long);
	product.slogan = CreateLanguageString(raw.public_data.slogan);
	product.list = CreateLanguageString(raw.public_data.list);
	product.package = CreateLanguageString(raw.public_data.package);
	product.package_dimensions = CreateLanguageString(raw.public_data.package_dimensions);
	product.vse_amount = CreateLanguageString(raw.public_data.vse_amount);
	product.vse_pallet = CreateLanguageString(raw.public_data.vse_pallet);
	product.vse_package = CreateLanguageString(raw.public_data.vse_package);
	product.vse_dimensions = CreateLanguageString(raw.public_data.vse_dimensions);
	product.ean = CreateLanguageString(raw.public_data.ean);


	product.collection_ids = CreateLanguageString(raw.public_data.collection_ids);
	product.vse_ean = CreateLanguageString(raw.public_data.vse_ean);
	product.bio = CreateLanguageString(raw.public_data.bio);
	product.vegan = CreateLanguageString(raw.public_data.vegan);
	product.fairtrade = CreateLanguageString(raw.public_data.fairtrade);
	product.geo = CreateLanguageString(raw.public_data.geo);

	product.delivery_date = CreateLanguageString(raw.delivery_date);
	product.price_marginal_costs = CreateLanguageString(raw.price_marginal_costs);
	product.price_out = CreateLanguageString(raw.price_out);
	product.price_contribution_margin = CreateLanguageString(raw.price_contribution_margin);
	product.price_evp = CreateLanguageString(raw.price_evp);
	product.price_trading_margin = CreateLanguageString(raw.price_trading_margin);

	product.is_new = CreateLanguageString(raw.public_data.is_new).Get();
	product.organic_certification  = CreateLanguageString(raw.public_data.organic_certification);
  product.tags = CreateLanguageString(raw.public_data.tags);

	//product.domain_id = raw.domain_id;
//TODO shoul by in product_ata
	product.images = [];
	product.videos = ['_dummy/_video_01.mp4'];
	product.badge_img = '_dummy/badge.png';
	product.logo_img = '_dummy/logo.png';
	product.link = null;
	product.info_img= '_dummy/info_img.jpg';

	product.thumb_print= '_dummy/thumb_print.jpg';
	product._3d = null;
	product._3d_glb = null;
	product._3d_usdz = null;
	product.thumb_midi = '_dummy/menu_img.jpg';
	product.thumb = '_dummy/thumb_img.jpg';
	product.data = {images:[], videos:[]};

  product.subproducts =  [];

  product.table = raw.public_data.table
  if(raw.public_data.table)
  {
   // console.log(product.name, raw.subproducts.length)
    for(let i in raw.public_data.table)
    {
      let pro = new Product();

      pro.collection_ids = raw.public_data.table[i].public_data.collection_ids.split(",");
      pro.list = CreateLanguageString(raw.public_data.table[i].public_data.list);
      pro.name  = CreateLanguageString(raw.public_data.table[i].name);
      pro.product_id  = CreateLanguageString(raw.public_data.table[i].public_data.product_id);

      pro.ean  = CreateLanguageString(raw.public_data.table[i].public_data.ean);
      pro.subrow  = CreateLanguageString(raw.public_data.table[i].public_data.subrow);
      pro.vse_package  = CreateLanguageString(raw.public_data.table[i].public_data.vse_package);
			pro.vse_amount  = CreateLanguageString(raw.public_data.table[i].public_data.vse_amount);
      pro.vse_dimensions  = CreateLanguageString(raw.public_data.table[i].public_data.vse_dimensions);
      pro.vse_ean  = CreateLanguageString(raw.public_data.table[i].public_data.vse_ean);
      pro.vse_pallet  = CreateLanguageString(raw.public_data.table[i].public_data.vse_pallet);
      pro.weight  = CreateLanguageString(raw.public_data.table[i].public_data.weight);

      product.subproducts.push(pro);
    }
  }
  //console.log(raw.public_data)
	if(raw.public_data.media)
	{

		if(raw.public_data.media.images && raw.public_data.media.images.length>0)
			product.images = raw.public_data.media.images;
		if(raw.public_data.media && raw.public_data.media.videos.length>0)
			product.videos = raw.public_data.media.videos;
		if(raw.public_data.media.glb)
			product._3d = raw.public_data.media.glb;
		if(raw.public_data.media.glb)
			product._3d_glb = raw.public_data.media.glb;
		if(raw.public_data.media.usdz)
			product._3d_usdz = raw.public_data.media.usdz;
		if(raw.public_data.media.badge)
			product.badge_img = raw.public_data.media.badge;
		if(raw.public_data.media.thumb_print)
			product.thumb_print = raw.public_data.media.thumb_print;
		if(raw.public_data.media.logo)
			product.logo_img = raw.public_data.media.logo;
		if(raw.public_data.media.info)
			product.info_img = raw.public_data.media.info;
		if(raw.public_data.media.menu)
			product.thumb_midi = 'thumb_256.'+(raw.public_data.media.menu.split(".")[raw.public_data.media.menu.split(".").length-1]);
		if(raw.public_data.media.menu)
			product.thumb = 'thumb_128.'+(raw.public_data.media.menu.split(".")[raw.public_data.media.menu.split(".").length-1]);
		if(raw.public_data.media.mobile)
			product.mobile = 'mobile_1024.'+(raw.public_data.media.mobile.split(".")[raw.public_data.media.mobile.split(".").length-1]);


	//	if(raw.data.menu && raw.data.menu.split(".")[raw.data.menu.split(".").length-1] == "png")
	//		console.log(raw.data.menu, product.thumb_midi, product.vse_ean)
	}
  //console.log(raw.public_data.media)

	let title = CreateLanguageString(product.name.Get('de'), product.name.Get('en'));
	//if(product.name)
	//	title += product.name.trim();
  //TODO CHeck i f strn g empty ->dont show
	if(product.weight.Get('de'))
  {

		title.de += getWeight(product.weight.Get('de'));
    title.en += getWeight(product.weight.Get('en'));
  }

	product.displayTitle = title;


	if(product._3d_glb && product._3d_usdz)
		product.link = product._3d_glb;
		//product.link = "hello wolrd";

 /* if(raw.name == "Marzipan Butter Törtchen Bratapfel")
    console.log(raw, JSON.parse(JSON.stringify(product)), product)*/
	return product;
}
function getWeight(val)
{
	if(!val)
		return null;

// eslint-disable-next-line
	if(val == parseInt(val))
		return ", " + val+"g";

	return ", " + val;
}

export class Product{



	GetNextSlide(actualSlide)
	{
		const slides = this.GetSlides();
		for(let i=0;i<slides.length;i++)
		{
			if(slides[i] === actualSlide)
			{
				if(i<slides.length-1)
					return slides[i+1];
			}
		}
		return actualSlide;
	}

	GetSlides()
	{
		let slides = [];

		if(this.videos && this.videos.length>0)
			slides.push(CONST._videos);

		if(this.images && this.images.length>0)
			slides.push(CONST._images);

		slides.push(CONST._info);

		if(HasPrices(this))
			slides.push(CONST._prices);

		return slides;
	}

	GetTitle()
	{
		if(this.displayTitle)
			return this.displayTitle;
		let title = "";
		if(this.name)
			title += this.name.trim();
		if(this.weight)
			title += getWeight(this.weight);

		this.displayTitle = title;
		return this.displayTitle;
	}
}