import React, {Component} from 'react';

import "./Content3D.css";

import {IsPortrait} from 'Utils';
import {GetProductDataUrl} from '_redux/api/_api';
import {SendToDevice_ArClicked} from 'LambertzHelper';
import QrCodeImage from 'ui/QrCodeImage';

class Content3D extends Component
{
	arClicked()
	{
		//console.log("arclicked", GetProductDataUrl(this.props.model, this.props.model._3d_usdz));
		SendToDevice_ArClicked(GetProductDataUrl(this.props.model, this.props.model._3d_usdz));
	}
	render()
	{
		if(this.props.model == null)
			return("")

		//console.log(GetProductDataUrl(this.props.model, this.props.model._3d));
		//GetProductDataUrl(this.props.model, this.props.model._3d)
		/*environment-image="studio_01.hdr"  alt="A 3D model"*/
		return(
			<div className="Content3D">
				
				<model-viewer 
					src={GetProductDataUrl(this.props.model, this.props.model._3d)} 
					ios-src={GetProductDataUrl(this.props.model, this.props.model._3d_usdz)} 
					alt="obj" 
					class="modelViewer" 
					auto-rotate 
					camera-controls
					shadow-intensity="0.1"
					environment-image="neutral"
					ar 
					ar-modes="scene-viewer webxr quick-look" 
					ar-scale="fixed" 
					autoplay 
					exposure="0.9"					
					>
					<button slot="ar-button" onClick={this.arClicked.bind(this)} style={{"backgroundImage":"url(images/icons/ar_btn.png)", backgroundSize:"contain", "backgroundColor":'red', "border": "none", "position":"absolute", "bottom":"16px", "right": "16px", "width":"3em", "height":"3em","position":"absolute"}}>
  				</button>

					</model-viewer>					
					{
					!IsPortrait() && this.props.qrcodeLink?
					<div className="qrArea">
						{this.props.qrcodeLink!=null?
							<QrCodeImage url={this.props.qrcodeLink} />			
							:""
						}
					</div>				
					:null
				}
			</div>			
		);
	}
}

export default Content3D;