
import { Component} from 'react';
import ReactDOM from 'react-dom';

class PrintPortal extends Component {
  render() {
		if(this.container == null)
		{
			this.container = document.getElementById("printcontainer");
			if(this.container  == null)
			{
				this.container = document.createElement("DIV");
				this.container.id = "printcontainer";
			}
			document.body.appendChild(this.container);
		}
		return ReactDOM.createPortal(this.props.children,this.container );
  }
}

export default PrintPortal