import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from 'i18n';

import {ValidateEmail} from 'Utils';
import {GetError} from 'Errors';
import {addAppMessage} from '_redux/api/appMessage';
import {register as register_} from '_redux/api/login';
import {APP_MESSAGE_TYPE_ERROR, APP_MESSAGE_TYPE_CLEAR} from '_redux/actions/app';
import {getRegisterDone, getRegisterError, getRegisterPending} from '_redux/reducers/login';
import {Link} from 'react-router-dom';
import TextInput from './../assets/TextInput';
import Button from './../assets/Button';

import "./CardLogin.css";
import { CONST } from '../../Constants';

class CardLoginRegister extends Component
{
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.state = {user:{val:""}, pass1:{val:""}, pass2:{val:""}};	
	}

	handleClick() {
		//const {passRegisterRequest} = this.props;
		
		this.props.addAppMessage(CONST._empty, CONST._empty, APP_MESSAGE_TYPE_CLEAR);
		
		let state = {...this.state};
		state.error = false;
		state.error1 = false;
		state.error2 = false;
		if (!ValidateEmail(this.state.user.val)) { 
			state.error = true;
			state.msg = GetError({id:"app001", "msg":i18n.t("login.register.input_no_user")});
			this.props.addAppMessage(state.msg.id, state.msg.msg, APP_MESSAGE_TYPE_ERROR );
			
		}
		else if(this.state.pass1.val.length<6)
		{
			state.error1 = true;
			state.msg = GetError({id:"app003", "msg":i18n.t("login.register.input_short_pass")});
			this.props.addAppMessage(state.msg.id, state.msg.msg, APP_MESSAGE_TYPE_ERROR );
		}
		else if(this.state.pass1.val !== this.state.pass2.val)
		{
			console.log("no", this.state.pass1, this.state.pass2 )
			state.error1 = true;
			state.error2 = true;
			state.msg = GetError({id:"app002", "msg":i18n.t("login.register.input_differ_pass")});
			this.props.addAppMessage(state.msg.id, state.msg.msg, APP_MESSAGE_TYPE_ERROR );
		}
		else
		{
			this.props.register({"name":this.state.user.val, "email":this.state.user.val, "password":this.state.pass1.val});
		}
		this.setState(state);
  }

	onSubmit(event){
		event.preventDefault();
		this.handleClick();
	}

	render()
	{
		
		const {result, error, pending} = this.props;

		if(result==null && error == null && pending == null)
		{
		
		}
		else if(!pending)
		{
			if(error != null && !this.state.error1 && !this.state.error2 && !this.state.error)
			{
				this.props.addAppMessage(error.info.id, i18n.t("login." + error.info.id + ".User already exists."), APP_MESSAGE_TYPE_ERROR);			
			}
		}

		if(result != null)
		{
			return (
				<div className="Login Card round">
				<div className="Card-title Login-title">
					<span>{i18n.t("login.register.title")}</span>
				</div>
				<div className="Card-content Login-content">
				{i18n.t("login.register.info_done").replace("[#email]", this.state.user.val)}
				<Button to="/login">{i18n.t("login.register.go_to_login_btn")}</Button>
				</div>
				</div>
			);
		}
		return(
			<div className="Login Card round">
				<div className="Card-title Login-title">
					<span>{i18n.t("login.register.title")}</span>
				</div>
				<div className="Card-content Login-content">
					<form onSubmit={this.onSubmit}>
					<span className="text">{i18n.t("login.register.info")}</span>
					<TextInput label={i18n.t("login.name.label")} placeholder={i18n.t("login.name.placeholder")} type="text" model={this.state.user} error={this.state.error} editable={true}></TextInput>
					<TextInput label={i18n.t("login.pass.label")} placeholder={i18n.t("login.pass.placeholder")} type="password" model={this.state.pass1} error={this.state.error1} editable={true}></TextInput>
					<TextInput label={i18n.t("login.pass2.label")} placeholder={i18n.t("login.pass2.placeholder")} type="password" model={this.state.pass2} error={this.state.error2} editable={true}></TextInput>
					<div className="btns">
					{false && this.state.msg && this.state.msg.msg?
						<>
						<span className="errors">{this.state.msg.msg}</span><br/>
						</>
						:null
					}
					<div onClick={this.handleClick} className="login_btn lmbrtz_btn">{i18n.t("login.register.btn")}</div>
									
					<input type="submit" className="hidden"></input>
					<br/>
					<Link className="link" to="/login">{i18n.t("login.back_login.link")}</Link>
					</div>
					</form>
				</div>
			</div>
		);
		}
	
}

const mapStateToProps = state => ({
	result: getRegisterDone(state),
	error: getRegisterError(state),
	pending: getRegisterPending(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({register:register_, addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CardLoginRegister);

