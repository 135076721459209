import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18n from 'i18n';
import {GetError} from 'Errors';
import {addAppMessage} from '_redux/api/appMessage';
import { APP_MESSAGE_TYPE_ERROR} from '_redux/actions/app';
import {setNewPass} from '_redux/api/login';

import {getPassResetDone, getPassResetError, getPassResetPending} from '_redux/reducers/login';

import TextInput from './../assets/TextInput';
import Button from './../assets/Button';


import "./CardLogin.css";
import { Redirect, Link } from 'react-router-dom';


class CardLoginPassReset extends Component
{
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.onSubmit = this.onSubmit.bind(this);		
		this.state = {email:{val:this.props.match.params.email}, emailid:{val:this.props.match.params.emailid}, pass1:{val:""}, pass2:{val:""}, passInputErorrs:false};
	}

	handleClick() {
		console.log("click");

		let state = {...this.state};
		state.error = false;
		state.error1 = false;
		state.error2 = false;


		if(this.state.pass1.val !== this.state.pass2.val)
		{
			let err = GetError({id:"app002", "msg":"Passes differ"});
			this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR );
			this.setState({...this.state, passInputErorrs:true, error:err });
		}
		else if(this.state.pass1.val.length<6)
		{
			let err = GetError({id:"app003", msg:"Pass to short"});
			this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR );
			this.setState({...this.state, passInputErorrs:true , error:err});
		}
		else
		{			
			this.setState({...this.state, passInputErorrs:false});
			this.props.setNewPass({"email":this.state.email.val,"pass_reset_token":this.state.emailid.val,"password":this.state.pass1.val});
		}
		
  }

	onSubmit(event){
		event.preventDefault();
		this.handleClick();
	}


	render()
	{		
		const {result, error, pending} = this.props;
		if(result==null && error == null && pending == null)
		{
		
		}
		else if(!pending)
		{
			if(error != null)
			{
			}
			if(result)
			{
				return(<Redirect to="/"></Redirect>)
			}
		}

		return(
			<div className="Login Card round">
				<div className="Card-title Login-title">					
					<span>{i18n.t("login.pass_reset.title")}</span>
				</div>
				<div className="Card-content Login-content">
					<form onSubmit={this.onSubmit}>
					<TextInput editable={true} label={i18n.t("login.name.label")} placeholder={i18n.t("login.name.placeholder")} type="text" model={this.state.email} className={this.props.match.params.email!=null?"hidden":""}></TextInput>
					<TextInput editable={true} label={i18n.t("login.pass_reset.emailid_label")} placeholder={i18n.t("login.pass_reset.emailid_placeholder")} type="text" model={this.state.emailid}  className={this.props.match.params.emailid!=null?"hidden":""}></TextInput>
					<TextInput editable={true} label={i18n.t("login.pass_reset.newpass1_label")} placeholder={i18n.t("login.pass_reset.newpass1_placeholder")} type="password" model={this.state.pass1}></TextInput>
					<TextInput editable={true} label={i18n.t("login.pass_reset.newpass2_label")} placeholder={i18n.t("login.pass_reset.newpass2_placeholder")} type="password" model={this.state.pass2}></TextInput>
					<span className="errors">{this.state.error!=null?this.state.error.msg:""}</span>
					<Button className="login_btn" onClick={this.handleClick}>{i18n.t("login.pass_reset.send_btn")}</Button>
					<Link className="link" to="/login">{i18n.t("login.register.go_to_login_btn")}</Link>
					<input type="submit" className="hidden"></input>
					</form>	
				</div>
			</div>
		);
		}
	
}

const mapStateToProps = state => ({
	result: getPassResetDone(state),
	error: getPassResetError(state),
	pending: getPassResetPending(state)
})

//const mapDispatchToProps = dispatch => bindActionCreators({passResetRequest: passResetRequest, addAppMessage: addAppMessage}, dispatch);
const mapDispatchToProps = dispatch => bindActionCreators({setNewPass:setNewPass, addAppMessage: addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CardLoginPassReset);

