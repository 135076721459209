import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';


import {logout} from '_redux/api/login';
import {updateUser} from '_redux/api/login';

import {getUser} from '_redux/reducers/login';
import {addAppMessage} from '_redux/api/appMessage';
import {APP_MESSAGE_TYPE_INFO} from '_redux/actions/app';
import "./UserDetails.css";
import i18n from 'i18n';
import { Redirect, withRouter } from 'react-router-dom';

import TextInputModel from '../assets/TextInput/TextInputModel';
import { CONST } from '../../Constants';

class UserDetails extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {};
		this.saveClicked = this.saveClicked.bind(this);
		this.logoutClicked = this.logoutClicked.bind(this);
	}
	saveClicked()
	{
		this.pendingSave= true;
	
		this.props.updateUser(this.props.user)
	}
	
	logoutClicked()
	{
    window.loggedout = true;
    window.pendingPath = '/';
		//localStorage.setItem(CONST._cookies.essential.loggedout, "true");
		this.props.logout();		
		
		this.setState({...this.state, redirect:"/login"});
	}

	componentDidUpdate()
	{
		if(this.state.redirect)
			this.setState({...this.state, redirect:null})		
	}

	render()
	{
		if(this.pendingSave)
		{
			this.props.addAppMessage("", i18n.t("user.saved"), APP_MESSAGE_TYPE_INFO);
			this.pendingSave	= false;
		}
		if(this.state.redirect)
			return(<Redirect to={this.state.redirect}></Redirect>)
		if(!this.props.user)
		{
			return("")
		}	


		return(
			<div id={this.props.id} className={"UserDetails Menu "+ this.props.className} >
				<div className="logo bgimg"></div>
				
				<div className="content">
				<div className="icon bgimg"></div>
					<TextInputModel label={i18n.t("user.firstname_label")} placeholder={i18n.t("user.firstname_placeholder")} type="text" model={this.props.user} _key="firstname" editable={true} error={this.state.user_err}></TextInputModel>
					<TextInputModel label={i18n.t("user.lastname_label")} placeholder={i18n.t("user.lastname_placeholder")} type="text" model={this.props.user} _key="lastname" editable={true} error={this.state.user_err}></TextInputModel>
					<TextInputModel label={i18n.t("user.email_label")} placeholder={i18n.t("user.email_placeholder")} type="text" model={this.props.user} _key="email" editable={false} error={this.state.user_err}></TextInputModel>
					
					<TextInputModel label={i18n.t("user.phone_label")} placeholder={i18n.t("user.phone_placeholder")} type="text" model={this.props.user} _key="phone" editable={true} error={this.state.user_err}></TextInputModel>
					<TextInputModel label={i18n.t("user.mobile_label")} placeholder={i18n.t("user.mobile_placeholder")} type="text" model={this.props.user} _key="mobile" editable={true} error={this.state.user_err}></TextInputModel>
					

					<TextInputModel label={i18n.t("user.company_label")} placeholder={i18n.t("user.company_placeholder")} type="text" model={this.props.user} _key="company" editable={true} error={this.state.user_err}></TextInputModel>
					<TextInputModel label={i18n.t("user.street_label")} placeholder={i18n.t("user.street_placeholder")} type="text" model={this.props.user} _key="street" editable={true} error={this.state.user_err}></TextInputModel>
					<TextInputModel label={i18n.t("user.city_label")} placeholder={i18n.t("user.city_placeholder")} type="text" model={this.props.user} _key="city" editable={true} error={this.state.user_err}></TextInputModel>
					<TextInputModel label={i18n.t("user.zip_label")} placeholder={i18n.t("user.zip_placeholder")} type="text" model={this.props.user} _key="zip" editable={true} error={this.state.user_err}></TextInputModel>
					<TextInputModel label={i18n.t("user.country_label")} placeholder={i18n.t("user.country_placeholder")} type="text" model={this.props.user} _key="country" editable={true} error={this.state.user_err}></TextInputModel>
				</div>
				<div className="lmbrtz_btn red">
					<div className="green" onClick={this.saveClicked}>{i18n.t("user.btn_save")}</div>
					<div className="red" onClick={this.logoutClicked}>{i18n.t("user.btn_logout")}</div>
				</div>
			</div>
		);
	}
}



const mapStateToProps = state => ({
	user: getUser(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({logout, updateUser, addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(UserDetails));