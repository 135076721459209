import { CONST } from "../../Constants";
import i18n from "../../i18n";

export function CreateLanguageString(de, en, type='string')
{
  let ls = new LanguageString()
  try
  {
    let ar = JSON.parse(de);
    if(Array.isArray(ar))
    {

      ls.de = ar.length>0?ar[0]:null;
      ls.en = ar.length>1?ar[1]:null;
      if(ar.length>1 && type === 'string' && (Array.isArray(ls.de) || Array.isArray(ls.en)))
      {
        if(ls.de == "" && ls.en != ""){
          ls.de = ls.en;
        }
        else if(ls.en == "" && ls.de != ""){
          ls.en = ls.de;
        }
      }

     /* if(type === 'array'){
        ls.de = ls.de==null?null:ls.de.split('\n');
        ls.en = ls.en==null?null:ls.en.split('\n');
      }
     */
      return ls;
    }
  }
  catch(Ex){

  }
	
  ls.de = de?de:null;
  ls.en = en?en:null;

 /* if(type === 'array'){
    ls.de = ls.de==null?null:ls.de.split('\n');
    ls.en = ls.en==null?null:ls.en.split('\n');
  }*/
  return ls;
}

class LanguageString
{
  Get(lang = null)
  {
    if(lang == null)
      lang = i18n.language;
    if(this.de === 'market_GER')
      return i18n.t('markets.GER')
    else if(this.de === 'market_INT')
      return i18n.t('markets.INT')
    else if(this.de === 'market_POL')
      return i18n.t('markets.POL')
    else if(this.de === 'new_products_group_name')
      return i18n.t('new_products_group_name')
    
    if(lang === CONST.de)
    {
      if(this.de != null)
        return this.de;
      if(this.en != null)
        return this.en;
    }
    else if(lang === CONST.en)
    {
      if(this.en != null)
        return this.en;
      if(this.de != null)
        return this.de;
    }
    //console.error("Translation not found", this);
    return CONST._empty;
  }
}
