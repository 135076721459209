
import {RESPONSE_TYPE} from './../api/_api.js';

export const DOMAIN_GET_PENDING = 'DOMAIN_GET_PENDING';
export const DOMAIN_GET_SUCCESS = 'DOMAIN_GET_SUCCESS';
export const DOMAIN_GET_ERROR = 'DOMAIN_GET_ERROR';

export const DOMAIN_UPDATE_USER_PENDING = 'DOMAIN_UPDATE_USER_PENDING';
export const DOMAIN_UPDATE_USER_SUCCESS = 'DOMAIN_UPDATE_USER_SUCCESS';
export const DOMAIN_UPDATE_USER_ERROR = 'DOMAIN_UPDATE_USER_ERROR';


export const DOMAIN_GET_USERS_PENDING = 'DOMAIN_GET_USERS_PENDING';
export const DOMAIN_GET_USERS_SUCCESS = 'DOMAIN_GET_USERS_SUCCESS';
export const DOMAIN_GET_USERS_ERROR = 'DOMAIN_GET_USERS_ERROR';

export const DOMAIN_DELETE_USER_PENDING = 'DOMAIN_DELETE_USER_PENDING';
export const DOMAIN_DELETE_USER_SUCCESS = 'DOMAIN_DELETE_USER_SUCCESS';
export const DOMAIN_DELETE_USER_ERROR = 'DOMAIN_DELETE_USER_ERROR';



export function fetchLoadUsers(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: DOMAIN_GET_USERS_PENDING, payload:info};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: DOMAIN_GET_USERS_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: DOMAIN_GET_USERS_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function fetchDomains(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: DOMAIN_GET_PENDING, payload:info};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		//_setToken(payload.token);
		
		return {
			type: DOMAIN_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: DOMAIN_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function deleteUser(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: DOMAIN_DELETE_USER_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
	//	_setToken(payload.token);
		return {
			type: DOMAIN_DELETE_USER_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: DOMAIN_DELETE_USER_ERROR,
			info: info,
			payload: payload
		};
	}
}
export function updateUser(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: DOMAIN_UPDATE_USER_PENDING};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
	//	_setToken(payload.token);
		return {
			type: DOMAIN_UPDATE_USER_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: DOMAIN_UPDATE_USER_ERROR,
			info: info,
			payload: payload
		};
	}
}
