import {
	DOMAIN_GET_PENDING, DOMAIN_GET_SUCCESS, DOMAIN_GET_ERROR, 
	DOMAIN_UPDATE_USER_PENDING, DOMAIN_UPDATE_USER_SUCCESS, DOMAIN_UPDATE_USER_ERROR,
	DOMAIN_GET_USERS_PENDING, DOMAIN_GET_USERS_SUCCESS, DOMAIN_GET_USERS_ERROR,
	DOMAIN_DELETE_USER_PENDING, DOMAIN_DELETE_USER_SUCCESS, DOMAIN_DELETE_USER_ERROR,
	
} from './../actions/domain';

import {
 LOGOUT_SUCCESS
} from './../actions/login';

import {
	PRODUCT_GET_PENDING, PRODUCT_GET_SUCCESS, PRODUCT_GET_ERROR	
} from './../actions/product';

import {IMPORT_PRODUCTS_SUCCESS}  from './../actions/product';

import {LOAD_LOCAL_DATA, FORCE_SYNC_DATA}  from './../actions/app';

import {initialState}  from 'initialState.js';
import { ParseDomains, SearchDomain } from '../_models/Domain';
import { ParseGroups, SearchGroup, MapProductsToGroups, AddDynamicGroup } from '../_models/Group';
import { ParseProducts } from '../_models/Product';
import {CreateProductsMap} from 'LambertzHelper';
import { CONST } from '../../Constants';


export function domainReducer(state = initialState, action) {
	
	switch(action.type) {
		case FORCE_SYNC_DATA:
		
			return{
				...state,
				pending: false,
				synced:false,
				domains: [],
				actualDomain: null,
				products_synced:false,
				groups:null,
				products:null,
				localLoaded:true,
				version:  {version:0, version_type:''}
			};
		case LOAD_LOCAL_DATA:
      console.error("deprecated")
			let localDomains = localStorage.getItem(CONST._cookies.offline.domains_payload);
			var domains = [];
			var actualDomain = {};
			if(localDomains != null)
			{
				let payload = JSON.parse(localDomains);
				if(payload!=null)
				{
					domains = ParseDomains(payload.domains);
					actualDomain = SearchDomain(actualDomain!=null?actualDomain.uuid:null, domains);
					if(domains != null && domains.length>0)
						actualDomain = domains[0];
				}
				
			}

			let localProducts = localStorage.getItem(CONST._cookies.offline.products_payload);
			var products = null;
			var groups = null;
			var version = state.version;
			if(localProducts != null)
			{
				let payload = JSON.parse(localProducts);
				if(payload!=null)
				{
          //(lng, ns, key, options))
					products = ParseProducts(payload.products);
					groups = ParseGroups(payload.groups);
					if(products.length>0)
						AddDynamicGroup(groups, products, "is_new", 'new_products_group_name');
					MapProductsToGroups(groups, products);
					CreateProductsMap(products);
					SearchGroup("create map", groups);
					version = {version:payload.version, version_type:payload.version_type};

				}
			}

			return{
				...state,
				pending: false,
				synced:false,
				domains: domains,
				actualDomain: actualDomain,
				products_synced:false,
				groups: groups,
				products:products,
				localLoaded:true,
				version: version
			};
		case DOMAIN_GET_PENDING: 
			return {
			...state,
			
			error: null,
			pending: true,		
		}
		case LOGOUT_SUCCESS:
			return {
				...state,
				products_synced:false,
				synced:false,
				actualDomain: null,
				users:[],
				domains:[],
				groups:[],
				error: null,
				pending: true,		
		}	
		case DOMAIN_GET_SUCCESS:		
			//console.log(action.payload.domains)
			// eslint-disable-next-line
			var domains = ParseDomains(action.payload.domains);
				// eslint-disable-next-line
			var actualDomain = SearchDomain(actualDomain!=null?actualDomain.uuid:null, domains);
			
			if(domains != null && domains.length>0)
				actualDomain = domains[0];
      
		//	updateLocalstorage(action.payload);

			return {
				...state,
				synced:true,
				pending: false,
				domains: domains,
				actualDomain: actualDomain
				
		}
		case DOMAIN_GET_ERROR:	
      console.log(action)				
			return {
				...state,
				pending: false,
				error: action.info,				
		}
		case PRODUCT_GET_PENDING: 
      console.log("pedning")
			return {
			...state,
			error: null,
			pending: false,		
		}
		case PRODUCT_GET_SUCCESS:	
    	if(action.payload.nochanges)
				return{...state,	products_synced:true, 	pending: false}	
			// eslint-disable-next-line
     
      //localStorage.setItem("tmp_products", JSON.stringify(action.payload.products));
			var products = ParseProducts(action.payload.products);
      // eslint-disable-next-line
			var groups = ParseGroups(action.payload.groups);
			//console.log(groups)
      groups.sort((a,b)=>{
        const _s =["GER", "POL", "INT"];
        //console.log(a.internal_id, _s.indexOf(a.internal_id), b.internal_id, _s.indexOf(b.internal_id))
        if(_s.indexOf(a.internal_id) == -1)
          return 1;
        return _s.indexOf(a.internal_id)<_s.indexOf(b.internal_id)?-1:1
      })
      MapProductsToGroups(groups, products);

      for(let i in groups)
      {
      
        if(products.length>0)
        {
          
			  	AddDynamicGroup(groups[i].children, groups[i].products, "is_new", 'new_products_group_name', groups[i].internal_id);
        }
      }
      //console.log(groups)
			CreateProductsMap(products);
			SearchGroup("create map", groups);
	
			//updateLocalstorage(null, action.payload);

			return {
				...state,
				products_synced:true,
				pending: false,
				groups: groups,
				products:products,
				version:{version:action.payload.version, version_type:action.payload.version_type}
		}
		case PRODUCT_GET_ERROR:					
			return {
				...state,
				pending: false,
				error: action.info,				
			}
		case DOMAIN_UPDATE_USER_PENDING: 
			return {
			...state,
			error: null,
			pending: true
			}
		case DOMAIN_UPDATE_USER_SUCCESS:	
			let found = false;
			for(let i=0; i<state.users.length;i++)
			{
				if(state.users[i].uuid === action.payload.uuid)
				{
					state.users[i] = action.payload;
					found = true;
					break;
				}
			}
			if(!found)
				state.users.push(action.payload)
			return {
				...state,
				users:[...state.users],
				pending: false,											
			}
		case DOMAIN_UPDATE_USER_ERROR:					
			return {
				...state,
				pending: false,
				error: action.info
			}


		case DOMAIN_GET_USERS_PENDING: 
			return {
			...state,
			error: null,
			pending: true
			}
		case DOMAIN_GET_USERS_SUCCESS:	
			let users = [];
			if(action.payload.result != null && Array.isArray(action.payload.result))
				users = action.payload.result ;
			return {
				...state,
				users:users,
				pending: false,									
			}
		case DOMAIN_GET_USERS_ERROR:					
			return {
				...state,
				pending: false,
				error: action.info
			}

		case DOMAIN_DELETE_USER_PENDING: 
			return {
			...state,
			error: null,
			pending: true
			}
		case IMPORT_PRODUCTS_SUCCESS:
			
			return {...state, version:{...state.version, version:action.payload.version}}
		case DOMAIN_DELETE_USER_SUCCESS:	
			let _users = [];
			for(let i=0; i<state.users.length;i++)
			{
				if(state.users[i].uuid !== action.payload.uuid)
				{
					_users.push(state.users[i])
				}
			}
			
			return {
				...state,
				users:_users,
				pending: false,									
			}
		case DOMAIN_DELETE_USER_ERROR:					
			return {
				...state,
				pending: false,
				error: action.info
			}

		default: 
			return state;
	}
}



export const getDomains = state => state.domain.domains;
export const getActualDomain = state => state.domain.actualDomain;
export const getProducts = state => state.domain.products;
export const getGroups = state => state.domain.groups;
export const getUsers = state => state.domain.users;
export const isSynced = state => state.domain.synced;
export const GetVersion = state => state.domain.version;
export const areProductsSynced = state => false;//state.domain.products_synced;