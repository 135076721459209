import {CONST} from 'Constants';
import i18n from 'i18n';
export function FindBreadCrumb(id, children, breadCrumb)
{
	for(let i=0; i<children.length;i++)
	{
		if(children[i].uuid === id)
		{	
			breadCrumb.push(children[i].uuid);
			return breadCrumb;
		}
		let s = FindBreadCrumb(id, children[i].children, breadCrumb);
		if(s != null)
		{
			s.push(children[i].uuid);
			return s;
		}
		for(let p=0; p<children[i].products.length;p++)
		{
			if(children[i].products[p].uuid === id)
			{				
				breadCrumb.push(children[i].products[p].uuid);
				breadCrumb.push(children[i].uuid);
				return breadCrumb;
			}
		}
	}

	return null;
	
}


export function GetChildrenInEntry(id, children)
{
	
	if(id === CONST._empty)
		return children;
	let s = FindEntry(id, children);
	if(s != null)
	{
		if(s.products && s.products.length>0)
		{
			
			let prs=[];
			for(let i=0; i<s.products.length; i++)
			{
				
				if(s.products[i].is_copy === false || s.products[i].internal_id.slice(-1) !== '-')
					prs.push(s.products[i]);
			}
			return prs;
		}
		if(s.children)
			return s.children;
		return [s];
	}
	return [];	
}


let entrymap = new Map();
export function FindEntry(id, children)
{
	if(entrymap.has(id))
		return entrymap.get(id);
	for(let i=0; i<children.length;i++)
	{
			if(children[i].uuid === id)
			{
				entrymap.set(id, children[i]);
				return entrymap.get(id);
			}

			if(children[i].children != null && children[i].children.length>0)
			{
				let result = FindEntry(id, children[i].children);
				if(result != null)
					return result;
			}

			if(children[i].products != null && children[i].products.length>0)
			{
				let result = FindEntry(id, children[i].products);
				if(result != null)
					return result;
			}
	}
	return null;
}

//let collectionEntrymap = new Map();
export function FindCollectionEntry(id, products)
{
	for(let i=0; i<products.length;i++)
	{
			if(products[i].internal_id === id || products[i].internal_id === id+"-")// "-" at the end marks double used internalids
			{
				return products[i];
			}
	}
	return null;
}
export function FindCollectionsWithEntry(id, products)
{
	let entries = [];
	for(let i=0; i<products.length;i++)
	{
		if(products[i].collection_ids.indexOf(id)>-1 )
		{
			let found=false;
			
			for(let j=0; j<entries.length;j++)
			{
				if(entries[j].uuid === products[i].uuid)	
				{
					found = true;
					break;
				}
			}
			if(!found)
				entries.push(products[i]);
		}
	}
	return entries;
}

export function DynamicSort(property) {
	var sortOrder = 1;
	if(property[0] === "-") {
			sortOrder = -1;
			property = property.substr(1);
	}
	return function (a,b) {
			/* next line works with strings and numbers, 
			 * and you may want to customize it to your needs
			 */
			var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
			return result * sortOrder;
	}
}

export function IsProduct(entry)
{
	if(entry != null && entry.vse_ean)
		return true;
	return false;
}

function findCopies(id)
{
	
	let copies = [];
	let product = FindProduct(id);
	if(!product)
		return copies;
	/* eslint-disable */
	for(let i in productsMap)
	{
		if(productsMap[i].is_copy && productsMap[i].internal_id.indexOf(product.internal_id)>-1)
			copies.push(productsMap[i]);		
	}
	/* eslint-enable */
	return copies;
}

export function GetArLink(item)
{	
	if(item._3d_glb && item._3d_usdz)
	{

		//return document.location.origin.split("localhost:").join("192.168.178.172:")+CONST._slash+CONST._hash+CONST.pathes.product+item.uuid+CONST._slash+CONST._3d;
		return document.location.origin+CONST._slash+CONST._hash+CONST.pathes.product+item.uuid+CONST._slash+CONST._3d;
	}
}
export function GetPackages(id, _products)
{
	
	let products = [];
	if(_products == null)
	{
		_products = [];
	}
  
	if(productsMap)
	{
		/* eslint-disable */
		for(let uuid in productsMap)
		{
			//products.push(productsMap[uuid]);
   
		}
		/* eslint-enable */

    let pr = productsMap[id];
    if(pr)
      return [pr].concat(pr.subproducts);
	}
	
  
  
	let entries = [];
	let entry = FindProduct(id);
  if(entry)
    return [entry].concat(entry.subproducts);

	if(entry == null)
		return [];

	entries.push(entry);

	let dentries = FindCollectionsWithEntry(entry.internal_id, products);
	if(dentries.length>0)
		entries = entries.concat(dentries);

	
	if(entry.collection_ids.length>0)
	{
		let ids = entry.collection_ids.split(",");
		for(let i=0; i<ids.length;i++)
		{			
			let centry = FindCollectionEntry(ids[i], products);
			if(centry)
				entries.push(centry);
		
		}
		
	}	

	let copies = findCopies(id);
	
	for(let i=0; i<copies.length;i++)
	{
		let found = false;
		for(let j=0; j<entries.length;j++)
		{
			if(entries[j].uuid === copies[i].uuid)
				found = true;
		}
		if(!found)
			entries.push(copies[i]);
	}
	return entries;
}

let searchmap = null;
export function SearchEntries(needle, groups)
{
	let results = [];
	needle = needle.toUpperCase();
	
	let needles = needle.split("%20");
	
	if(searchmap == null || searchmap[i18n.language] == null)
	{
		createSearchMap(groups)
	}

	let found = false;
	//eslint-disable-next-line
	for(let uuid in searchmap[i18n.language])
	{
		for(let j=0; j<searchmap[i18n.language][uuid].length;j++)
		{
			for(let n=0;n<needles.length;n++)
			{
				if(searchmap[i18n.language][uuid][j].indexOf(needles[n])>=0)
				{
					
					let f = FindProduct(uuid);
					if(f && f.is_copy)
						continue;
					found = true;
					break;
				}
			}
			if(found)
				break;
		
		}
		if(found)
			results.push(FindEntry(uuid, groups));

		found = false;
	}
	
	return results;
}

function createSearchMap(entries)
{
  if(searchmap == null)
    searchmap={};
  if(searchmap[i18n.language] == null)
    searchmap[i18n.language]={};



	if(entries == null || entries.length === 0)
		return;
	
	for(let i=0; i<entries.length;i++)
	{
    if(entries[i].name == null)
      continue;
		searchmap[i18n.language][entries[i].uuid] = [(entries[i].name.Get?entries[i].name.Get():entries[i].name).toUpperCase()];
		if(IsProduct(entries[i]) && !entries[i].is_copy)
		{
			searchmap[i18n.language][entries[i].uuid].push(entries[i].vse_ean.Get().toUpperCase());
			searchmap[i18n.language][entries[i].uuid].push(entries[i].internal_id.Get().toUpperCase());
			searchmap[i18n.language][entries[i].uuid].push(entries[i].product_id.Get().toUpperCase());
			searchmap[i18n.language][entries[i].uuid].push(entries[i].ingridient_keys.Get().toUpperCase());
      searchmap[i18n.language][entries[i].uuid].push(entries[i].tags.Get().toUpperCase());
		}
		else
		{
			createSearchMap(entries[i].children);
			createSearchMap(entries[i].products);
		}
	}

	
}

let productsMap = null;
export function FindProduct(uuid)
{
	if(productsMap == null)
		return null;
	return productsMap[uuid]	
}
export function CreateProductsMap(products)
{
	productsMap = {};
	products.map((product)=>productsMap[product.uuid] = product);
}

export function ParsePercent(val)
{
  
	if(val == null)
		return "";
	if(val.indexOf("%")>-1)
		return val;
	else
	{
  	let v = val*100;
    
    v = Math.round(v*10)/10;
		if(v%Math.floor(v)<0.1)
			return (Math.floor(v))+"%";
	
		return v+"%";
	}
}

export function GetTitle(item)
{
  
	let title = "";
  let name = "";
  if(item && item.name.Get){
   name = item.name.Get();
  }
  else
    name = item?.name;


	if(name)
		title += name.trim();
	if(item && item.weight)
		title += getWeight(item.weight.Get());

	//item.displayTitle = title;
	return title;
}


export function GetTitleWithPackage(item)
{
	if(item.displayTitleWithPackage)
		return item.displayTitleWithPackage;
	let title = "";
  if(item && typeof(item.name) != 'string'){
    item.name = item.name.Get();
  }
	if(item && item.name)
		title += item.name.trim();
	if(item && item.weight)
		title += getWeight(item.weight);
	if(item && item.vse_package)
		title += ", "+(item.vse_package.Get?item.vse_package.Get():item.vse_package);

	item.displayTitleWithPackage = title;
	return item.displayTitleWithPackage;
}


function getWeight(val)
{
	if(!val)
		return "";
  val = (val.Get?val.Get():val);

	if(val == parseInt(val)) // eslint-disable-line
		return ", " + val+"g";
  if(val && val.length>0)
	  return ", " + (val.Get?val.Get():val);
  return "";
}

export function PrepareEanVkeForOutput(val)
{
 
  if(val == null)
    return "";
  if(val.Get)
    val = val.Get();

  if(Array.isArray(val))
    val = val.join("\r\n")
  else
    val = val.split(CONST._space).join(CONST._empty);
  

	val = val.replace(/[a-z](?=\d)/gi, '$& ')
  
  if(val.split(' ').join('') == parseInt(val.split(' ').join('')))
    return parseInt(val.split(' ').join(''));

	return val;
}


export function SendToDevice_Loaded()
{
	if(window.webkit != null && window.webkit.messageHandlers != null)
	{
		if(window.webkit.messageHandlers.jsMessageHandler != null)
		{
			window.webkit.messageHandlers.jsMessageHandler.postMessage("loaded");
		}
	}
}

export function SendToDevice_ArClicked(url)
{
	if(window.webkit != null && window.webkit.messageHandlers != null)
	{
		if(window.webkit.messageHandlers.jsMessageHandler != null)
		{
			
			window.webkit.messageHandlers.jsMessageHandler.postMessage("ar:"+url);
		}
	}
		
}