import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {getGroups} from '_redux/reducers/domain';
import {getUser} from '_redux/reducers/login';
import {getActualDomain as actualDomain} from '_redux/reducers/domain';

import "./Menu.css";
import i18n from 'i18n';
import { Link, Redirect, withRouter } from 'react-router-dom';

import {GetId, GetScope, IsPortrait} from 'Utils'
import { FindBreadCrumb} from 'LambertzHelper'
import { CONST } from '../../Constants';
import { withTranslation } from 'react-i18next';

class Menu extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {selected_id:null, redirect:null}
		this.itemClicked = this.itemClicked.bind(this);
	}

	itemClicked(e)
	{
		e.preventDefault();

		if(e.target.dataset.id)
		{
			this.setState({...this.state, redirect:"/product/"+e.target.dataset.id});
		}

		if(e.target.nodeName === "SPAN")
		{
			if(this.props.closed)
				this.props.closed();
		}
	}

	createFlatGroupChildren(depth, parent_id, children, entries)
	{
		if(children == null)
			return;

		for(let i=0; i<children.length;i++)
		{
			entries.push(<div className={"ia _"+depth} key={children[i].uuid+"_"+i}><span>{children[i].name}</span></div>)
			this.createFlatGroupChildren(depth+1, children[i].uuid, children[i].children, entries)
		}
		return entries;
	}

	creatGroupedChildren(depth, parent_id, children, breadCrumb)
	{
		let entries = [];
		if(children == null)
			return entries;
		for(let i=0; i<children.length;i++)
		{
			if(breadCrumb.includes(children[i].uuid))
			{
				entries.push(<div className={"ia active _"+depth} key={children[i].uuid+"_"+i}><span data-id={children[i].uuid}>{children[i].name.Get()}</span></div>)
				let chs = this.creatGroupedChildren(depth+1, children[i].uuid, children[i].children, breadCrumb);
				if(chs.length>0)
					entries.push(<div className={"ch opened"} key={children[i].uuid+"_"+i+"ch"}>{chs}</div>)


				if(children[i].products.length>0)
				{

					let prdcts = [];
					for(let p=0; p<children[i].products.length;p++)
					{

						if(!children[i].products[p].is_copy)
							prdcts.push(<div className={"ia prdct _"+(depth+1)+(breadCrumb.includes(children[i].products[p].uuid)?" active":"")} key={children[i].products[p].uuid+"_"+i+"_"+p}><span data-id={children[i].products[p].uuid}>{children[i].products[p].name.Get?children[i].products[p].name.Get():children[i].products[p].name}</span></div>)
					}
					entries.push(<div className={"ch opened"} key={children[i].uuid+"prdcts"+"_"+i}>{prdcts}</div>)
				}
			}
			else
			{

				if(!children[i].is_copy)
					entries.push(<div className={"ia _"+depth} key={children[i].uuid+"_"+i}><span data-id={children[i].uuid}>{children[i].name.Get()}</span></div>)
			}
		}
		return entries;
	}



	componentDidUpdate()
	{
		if(this.state.redirect != null)
			this.setState({...this.state, redirect:null})
	}
  languageChanged(lang)
  {
    //localStorage.setItem(CONST._cookies.comfort.language, lang);
    i18n.changeLanguage(lang)
  }

	render()
	{
		if(this.state.redirect != null)
			return(<Redirect to={this.state.redirect}></Redirect>)


		let breadCrumb = [];

		let isProductView = GetScope() === "product";
		let productId = GetId();

		if(this.props.groups && isProductView && productId)
			FindBreadCrumb(productId, this.props.groups, breadCrumb)

		let entries = [];
		entries = this.creatGroupedChildren(1, "", this.props.groups, breadCrumb)
	//	console.log(this.props.user, this.props.actualDomain)
		return(
			<div id={this.props.id} className={"Menu exception"+ this.props.className}>
				<div className="logo bgimg"></div>
				<div className="content" onClick={this.itemClicked}>
					<Link to={CONST.pathes.product}><span>{i18n.t("menu.home_btn")}</span></Link>

          <div to={CONST.pathes.product} className="lang active ia _1"><span>{i18n.t("menu.language.select")}</span></div>
          <div className="lang ch opened">

            <div
              className={"ia _2" + (i18n.language === CONST.de?CONST._active:CONST._empty)}
              onClick={()=>this.languageChanged(CONST.de)}>
                <span>{i18n.t("menu.language.german")}</span>
            </div>
            <div
              className={"ia _2" + (i18n.language === CONST.en?CONST._active:CONST._empty)}
              onClick={()=>this.languageChanged(CONST.en)}>
                <span>{i18n.t("menu.language.english")}</span>
            </div>

          </div>



					{!IsPortrait() && this.props.actualDomain && this.props.actualDomain.role<=1?<Link to={CONST.pathes.usermanagement}><span>
            {i18n.t("menu.usermanagement")}</span></Link>:null}
					{this.props.actualDomain && this.props.actualDomain.prole<=30?<Link to={CONST.pathes.presentations}><span>{i18n.t("menu.presentations")}</span></Link>:null}
					{entries}
					<Link to={CONST.pathes.contact}><span>{i18n.t("footer.contact")}</span></Link>
					{IsPortrait()?<Link to={CONST.pathes.imprint}><span>{i18n.t("footer.imprint")}</span></Link>:null}
					{IsPortrait()?<Link to={CONST.pathes.data_privacy}><span>{i18n.t("footer.data")}</span></Link>:null}
					{!IsPortrait() && this.props.actualDomain && this.props.actualDomain.role<=1?<Link to={CONST.pathes.dataimport}><span>{i18n.t("menu.dataimport")}</span></Link>:null}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	groups: getGroups(state),
	user: getUser(state),
	actualDomain:actualDomain(state),
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(Menu)));