
import React, { Component, Suspense, lazy  } from 'react';
import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import './App.css';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {getUser, getAuthPending, getLoginError} from './_redux/reducers/login';

import {auth} from '_redux/api/login';

import {getDomains as domains, getActualDomain as actualDomain, getProducts as products, isSynced as isDomainSynced, areProductsSynced} from './_redux/reducers/domain';

import { CONST } from './Constants';
import {setHeader} from '_redux/api/appMessage';
import LoginView from './views/LoginView';
import Header from './ui/Header';

import MainFooter from './ui/MainFooter';
import ProductView from './views/ProductView';
import SearchResultsView from './views/SearchResultsView';

import MessageAlert from './ui/assets/MessageAlert/MessageAlert';
import {IsMobile, IsPortrait, IsIos, IsAndroid, GetScope} from 'Utils';
import i18n from './i18n';
import AppData from './AppData';
import {SendToDevice_Loaded} from 'LambertzHelper';
import LoadingSpinner from 'ui/assets/LoadingSpinner';
import { withTranslation } from 'react-i18next';


const ImprintView = lazy(() => import('./views/ImprintView'));
const ModalPresentationView = lazy(() => import('./views/ModalPresentationView'));
const UserManagementView = lazy(() => import('./views/UserManagementView'));
const DataImportView = lazy(() => import('./views/DataImportView'));
const PresentationView = lazy(() => import('./views/PresentationView'));
const PresentationsView = lazy(() => import('./views/PresentationsView'));
const DataPreview  = lazy(() => import('./DataPreview/DataPreview'));


class App extends Component {
	constructor(props) {
		super(props);
		this.state = {}
		window.__setSafeArea = function(top, right, bottom, left)
		{
			this.setState({...this.state, safeArea:{top:top,right:right,bottom:bottom, left:left}})
		}.bind(this);

		window.__setDeviceId = function(id)
		{	
			this.setState({...this.state, deviceId:id})
		}.bind(this);

		window.__setOS = function(os)
		{
			if(os === CONST.ios)
				os = CONST.ios;
			else if(os === CONST.android)
				os = CONST.android;
			else
				os = CONST._empty;
			this.setState({...this.state, os:os});
		}.bind(this);

		this.splashLoginLoaded = localStorage.getItem(CONST._cookies.comfort.splashLoginLoaded)==="true";		
		this.focused = this.focused.bind(this);
		this.onResize = this.onResize.bind(this);
    if(window.pendingPath==null){
      if(this.props.history.location.pathname.indexOf("login")===-1)
        window.pendingPath = this.props.location.pathname;
      else
        window.pendingPath =  CONST.pathes.home;
    }
	}

	focused()
	{
		const b = document.body;
		const d = document.documentElement;
		const a = document.getElementsByClassName("App")[0];

		if(a == null)
			return;
		d.scrollLeft=0;
		b.scrollLeft = 0;
		a.scrollLeft = 0;
		d.scrollTop=0;
		b.scrollTop = 0;
		a.scrollTop = 0;
	}
	onResize()
	{
		this.focused();		
		this.setState({...this.state, w:window.innerWidth, h:window.innerHeight});
	}

	componentDidMount()
	{
		window.addEventListener('resize', this.onResize);
		window.addEventListener('focus', this.focused)

		SendToDevice_Loaded();
		this.onResize();

    this.componentDidUpdate();
	}
		
	componentWillUnmount()
	{
		window.removeEventListener('resize', this.onResize);
	}

	componentDidUpdate()
	{		
	
		if(!this.splashLoginLoaded && IsPortrait() && this.props.history.location.pathname.indexOf(CONST.pathes.loginSplash) > -1)
		{
		
			this.splashLoginLoaded = true;
			localStorage.setItem(CONST._cookies.comfort.splashLoginLoaded, "true");
		}

    if(!i18n.isInitialized)
    {
      setTimeout(function(){ 
          this.setState({})}.bind(this), 
          100
      );
    }
    else
    {
      if(localStorage.getItem("i18nextLng") == null || (localStorage.getItem("i18nextLng")!=CONST.de && localStorage.getItem("i18nextLng")!=CONST.en))
      {
        let l = navigator.language || navigator.userLanguage;
        if(l === CONST.de || l?.indexOf(CONST.de)>-1)
          i18n.changeLanguage(CONST.de);
        else
          i18n.changeLanguage(CONST.en);
      }
      //if(i18n.language !== localStorage.getItem(CONST._cookies.comfort.language))
      //i18n.changeLanguage(localStorage.getItem(CONST._cookies.comfort.language));      
    }
	}

  render() {
		if(!i18n.isInitialized)
    {
      document.title = "Lambertz Presenter - loading language";
      return "Loading";
    }
    
    //console.log(i18n.language)
    if(i18n.language.split('-').length>1){
      if(i18n.language.split('-')[0] === "de")
        i18n.changeLanguage(CONST.de);
      else
        i18n.changeLanguage(CONST.en);
    }
    //console.log(i18n.language)
    document.documentElement.lang = i18n.language === CONST.de?CONST.de:CONST.en;
    document.title = i18n.t("page_title");
    
		if(!this.splashLoginLoaded && IsPortrait() && this.props.history.location.pathname.indexOf(CONST.pathes.loginSplash) === -1 && GetScope()!=="product")
		{			
			return(<Redirect to={CONST.pathes.loginSplash}></Redirect>);
		}
		else if(window.loggedout && this.props.history.location.pathname.indexOf(CONST.pathes.login) === -1)
		{
		
			return(<Redirect to={CONST.pathes.login}></Redirect>);
		}
		let className = "App";
		/* eslint-disable */
		if(IsMobile())
			className += CONST._space+"mobile";
		else
			className += CONST._space+"desktop";
		if(IsPortrait())
			className += " "+"portrait";
		if(IsIos() || this.state.os == CONST.ios)
			className += CONST._space+CONST.ios;
		if(IsAndroid() || this.state.os == CONST.android)
			className += CONST._space+CONST.android;
		if((document.location+"").indexOf("produktwelt-lam")===-1)
			className += " debug";
		if(this.props.location.pathname.indexOf("/login")>-1)
			className += " login";
		if(window.innerHeight<570)
			className += " small";
		if(this.props.history.location.pathname.indexOf(CONST.pathes.loginSplash)>-1)
			className += " splash";
		
		this.lastuser = this.props.user;
		if(IsPortrait())
		{
			if(this.props.history.location.pathname.indexOf(CONST.pathes.presentations)>-1)
				this.props.setHeader({title:i18n.t("menu.presentations"), subtitle:""});
			else 
				this.props.setHeader(null);
		}
		
	
		return (
			<>
      <div className={className}>
				<div className="appContent">
				<Suspense fallback={<div></div>}>
				<Switch>
					<Route path='/login' component={LoginView}></Route>
					<Route path={CONST.pathes.modal_presentation} component={ModalPresentationView}></Route>
					<Route path={CONST.pathes.presentation} component={PresentationView}></Route>
					<Route path={CONST.pathes.presentations} component={PresentationsView}></Route>
					
					<Route path='/product' component={ProductView}></Route>
					<Route path='/search' component={SearchResultsView}></Route>
					<Route path={CONST.pathes.usermanagement} component={UserManagementView}></Route>
					<Route path={CONST.pathes.dataimport} component={DataImportView}></Route>
							
					<Route path={[CONST.pathes.contact, CONST.pathes.imprint, CONST.pathes.data_privacy, CONST.pathes.certificates]} component={ImprintView}></Route>
					<Route path='/' component={ProductView}></Route>
				</Switch>
				</Suspense>
				</div>
				<MainFooter></MainFooter>
				<Header size={window.innerWidth}></Header>
				<AppData></AppData>
				<MessageAlert></MessageAlert>
				<LoadingSpinner></LoadingSpinner>
        {/*<Suspense fallback={<div>Loading data viewer</div>}>
          <DataPreview></DataPreview>
    </Suspense>*/}
				<div className="deviceDebug">{navigator.platform === 'MacIntel'?"true":"false"}</div>
		  </div>					
			</>
    );
  }
}

const mapStateToProps = state => ({
	user: getUser(state),
	domains:domains(state),
	actualDomain:actualDomain(state),
	products: products(state),
	isDomainSynced:isDomainSynced(state),
	areProductsSynced:areProductsSynced(state),
	authPending:getAuthPending(state),
	authError:getLoginError(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({auth, setHeader}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(App)));