
import React, { Component, Suspense } from 'react';
import {Switch, Route} from 'react-router-dom';

import CardLogin from 'ui/_login/CardLogin';
import CardLoginRegister from 'ui/_login/CardLoginRegister';
import CardLoginPassForgot from 'ui/_login/CardLoginPassForgot';
import CardLoginPassReset from 'ui/_login/CardLoginPassReset';

import {IsPortrait} from 'Utils';
import './LoginView.css';
import IntroInfo from '../ui/IntroInfo';
import { CONST } from '../Constants';
import CardLoginSplash from '../ui/_login/CardLoginSplash';
class LoginView extends Component {
	
  render() {

	
		return (
      <div className="LoginView">
				<Suspense fallback={<div>Loading...</div>}>
				<div className="window">
					<div className="left">
					<Switch>
						<Route exact path='/login' component={CardLogin}></Route>
						<Route exact path={CONST.pathes.loginSplash} component={CardLoginSplash}></Route>
						<Route exact path='/login/pass_forgot' component={CardLoginPassForgot}></Route>
						<Route exact path='/login/register' component={CardLoginRegister}></Route>
						<Route path='/login/pass_reset/:email/:emailid' component={CardLoginPassReset}></Route>
						<Route path='/login/pass_reset/:email' component={CardLoginPassReset}></Route>
						<Route path='/login/pass_reset' component={CardLoginPassReset}></Route>
						<Route path='/login/:email/:hash' component={CardLogin}></Route>				
					</Switch>
					</div>
					{!IsPortrait()?
					<div className="right">
						<IntroInfo></IntroInfo>
					</div>
					:null}
				</div>
				</Suspense>				
		  </div>		
    );
  }
}
export default LoginView;