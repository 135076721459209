export const initialState = {
	apiReducer:	{
		api_jobs:0
	},
	app:
	{
		messages:[],
		db:null
	},
	login:
	{
		token:"",
		user:null
	},
	domain:
	{
		synced: false,
		products_synced:false,
		products:null,
		groups:null,
		localLoaded: false,
		users:[],
		version:{version:0, version_type:'', date:''},
		actualDomain:{},
		domains:[],
	},
	presentations:
	{
		synced: false,
		presentations:[],
		actualPresentation:null
	}
}