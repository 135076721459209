import React, {Component} from 'react';
import i18n from 'i18n';
import QRCode from 'qrcode.react';

class QrCodeImage extends Component
{
	render()
	{
    return <>
      <img alt="" src={i18n.language==='de'?"./images/icons/arBtn.svg":"./images/print_qr_en.png"} />
      <QRCode value={this.props.url?this.props.url:"0"} size={200} className="qrcode" renderAs="svg" includeMargin={false} />
    </>
	}
}
export default QrCodeImage;