import React, {Component} from 'react';
import i18n from "i18n";
import { connect } from 'react-redux';

import {isPending} from '_redux/reducers/api';

import "./LoadingSpinner.css";

class LoadingSpinner extends Component
{
	render()
	{
		return(
			<div className={"LoadingSpinner "+(this.props.pending===true?"visible":"") }>
				<div className="LoadingSpinner-content">
					{/*<div className="ani"></div>*/}
					<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
					<span>{i18n.t("loading")}</span>
				</div>
			</div>
		);		
	}
}

const mapStateToProps = state => ({
	pending: isPending(state)
})

export default connect(mapStateToProps,null)(LoadingSpinner);