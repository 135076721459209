import React, {Component} from 'react';

import "./Label.css";
class Label extends Component
{
	render()
	{
		
		return(
			<p className={"Label noselect" + (this.props.className?" "+this.props.className:"")}>
				<span className="Label-content">{this.props.children!=null?this.props.children:(this.props.value!==undefined?this.props.value:"label")}</span>				
			</p>
		);
	}
}
export default Label;