import React, {Component} from 'react';
import Label from './../Label';

import "./TextInput.css";

class TextInputModel extends Component
{

	constructor(props)
	{
		super(props);
		this.state = {value:""}
		this.onTextChange = this.onTextChange.bind(this);
	}

	onTextChange(event){
		this.props.model[this.props._key]=event.target.value;
		this.setState({value:event.target.value})		
	};

	render()
	{
		if(this.props.model==null)
		{
			return("Loading");
		}
		
		return(
			<div title={this.props.model!=null?this.props.model[this.props._key]:""} className={"TextInput"+ (this.props.className!=null?" "+this.props.className:"") + (this.props.editable?" editable":"")+ (this.props.error?" error":"")}> 
				<Label value={this.props.label}></Label>
				<input 
					type={this.props.type!=null?this.props.type:"text"} 
					name={this.props._key}
					id={this.props._key}
					className="TextInput-content" 
					placeholder={this.props.placeholder!=null?this.props.placeholder:""} 
					onChange={this.onTextChange} 
					value={this.props.model!=null?this.props.model[this.props._key]:""}>
					</input>
			</div>
		);
	}
}
export default TextInputModel;