import React, {PureComponent} from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18n';
import { CONST } from 'Constants';
import {GetProductDataUrl} from '_redux/api/_api';

import {ParsePercent, GetPackages, PrepareEanVkeForOutput, GetArLink, GetTitle} from 'LambertzHelper';
/*import {GetPackages, ParsePercent, , PrepareEanVkeForOutput, GetTitleWithPackage} from 'LambertzHelper';*/
import "./ProductDetailPrintView.css";

import QrCodeImage from 'ui/QrCodeImage';


class ProductDetailPrintView extends PureComponent
{
	render()
	{
		if(this.props.data == null)
			return("");
		this.item = this.props.data;

		let packages = GetPackages(this.item.uuid);

		return(
			<div className="ProductDetailPrintView">
				<div className="header">
					<img src="./images/lambertz-logo.png" alt="logo" />
					<div className="qrArea">
						{GetArLink(this.item)!=null?
              <QrCodeImage url={GetArLink(this.item)} />
							:""
						}
					</div>


				</div>
				<div className="content">
					<div className="left">
						<div className="head">
						<div>
							<h1>{this.item.name.Get?this.item.name.Get():this.item.name}</h1>
							<h2>{this.item.subrow.Get()}</h2>
							<h3>{i18n.t("product.info.article_no.label") + CONST._space+ this.item.product_id.Get()}</h3>
						</div>

					</div>

					<div className="part">
					{this.item.list && this.item.list.Get().length>0?
					<>
						<div className="hr"></div>
						<div className="list">
							{this.item.list.Get().split("\n").map((d, index) => <div key={index + d}>{d}</div>)}
						</div>
						<div className="hr"></div>
					</>
					:null}
					</div>

					<div className="part split">
						<img className="logo" src={GetProductDataUrl(this.item, this.item.logo_img)} alt={CONST._space}></img>
						<div className="ingredients">
						{this.item.ingridient_keys.Get().length>1?
							this.item.ingridient_keys.Get().split("\n").map((d, index) => <p key={index + d}><span className="lab">{d}</span><span className="val">{ParsePercent(this.item.ingridient_values.Get().split("\n")[index])}</span></p>)
						:
							null
						}
						</div>
					</div>

					</div>
					<div className="right bgimg"><img src={GetProductDataUrl(this.item, this.item.info_img)} alt="Bild" /></div>

				</div>
				<div className="foot">
					<div className="badges">
						  {this.item.organic_certification?.Get()?<div className="organic_certification">{this.item.organic_certification.Get()}</div>:null}
							{this.item.bio.Get()?<img src={"/images/icon_bio.png"} alt={CONST._space} ></img>:null}
							{this.item.vegan.Get()?<img src={"/images/icon_vegan.png"} alt={CONST._space}></img>:null}
							{this.item.fairtrade.Get()?<img src={"/images/icon_fairtrade.png"} alt={CONST._space}></img>:null}

							{this.item.geo.Get().toUpperCase().indexOf("AACHENER")>-1?<img src={"/images/icon_aacher_printen.png"} alt={CONST._space}></img>:null}
							{this.item.geo.Get().toUpperCase().indexOf("NÜRNBERGER")>-1?<img src={"/images/icon_neurnberger_lebkuchen.png"} alt={CONST._space}></img>:null}
						</div>
					<table className="packages">
						<thead>
						<tr>
							<th>{i18n.t("product.info.packages_table.head.name")}</th>
							<th>{i18n.t("product.info.packages_table.head.articleNo")}</th>
							<th>{i18n.t("product.info.packages_table.head.package")}</th>
							<th>{i18n.t("product.info.packages_table.head.dimensions")}</th>

							<th>{i18n.t("product.info.packages_table.head.vse_amount")}</th>
							<th>{i18n.t("product.info.packages_table.head.vse_pallet")}</th>


							<th>{i18n.t("product.info.packages_table.head.vse_package")}</th>
							<th>{i18n.t("product.info.packages_table.head.vse_dimensions")}</th>
							<th>{i18n.t("product.info.packages_table.head.vse_ean")}</th>
							<th>{i18n.t("product.info.packages_table.head.ean")}</th>
						</tr>
						</thead>
						<tbody>
						{
							packages.map((item, i)=>
							<tr key={i}>
								<td>{GetTitle(item)}</td>
								<td>{item.product_id.Get()}</td>
								<td>{item.package && item.package.Get()}</td>
								<td>{item.package_dimensions && item.package_dimensions.Get()}</td>

								<td>{item.vse_amount.Get()}</td>
								<td>{item.vse_pallet.Get()}</td>

								<td>{item.vse_package && item.vse_package.Get()}</td>
								<td>{item.vse_dimensions && item.vse_dimensions.Get()}</td>
								<td>{PrepareEanVkeForOutput(item.ean && item.ean.Get())}</td>
								<td>{item.vse_ean.Get()}</td>
							</tr>
							)}

						</tbody>
					</table>

				</div>
			</div>
		);
	}
}

export default withTranslation()(ProductDetailPrintView);