import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from "react-i18next";
import i18n from "i18n";

import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import {apiReducer} from './_redux/reducers/api';
import {appReducer} from './_redux/reducers/app';
import {loginReducer} from './_redux/reducers/login';
import {domainReducer} from './_redux/reducers/domain';
import {presentationReducer} from './_redux/reducers/presentation';
import {importReducer} from './_redux/reducers/import';


import {initialState} from './initialState';
import "./i18n";

import App from 'App';
import './index.css';

const middlewares = [thunk];
const rootReducer = combineReducers({apiReducer, app:appReducer, login:loginReducer, domain:domainReducer, presentations:presentationReducer, import:importReducer});
const store = createStore(rootReducer, initialState, applyMiddleware(...middlewares));

ReactDOM.render(
	<Provider store={store}>
		<HashRouter> 
			<I18nextProvider i18n={i18n}>
				<App></App>
			</I18nextProvider>
		</HashRouter> 	
	</Provider>
	,
  document.getElementById('root')
);
