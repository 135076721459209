import React, {Component} from 'react';

import "./IntroInfo.css";
import i18n from 'i18n';


class IntroInfo extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {id:0, paused:true};
		this.entries = [/*{type:"pdf", id:"01"},*/{type:"video", id:"01"}]	
		this.clicked = this.clicked.bind(this);
		this.videoRef = React.createRef();
		this.playClicked = this.playClicked.bind(this);
	}

	clicked(dir)
	{
		if(dir<0 && this.state.id>0)
			this.setState({...this.state, id:this.state.id-1})
		else if(dir>0 && this.state.id<this.entries.length-1)
		this.setState({...this.state, id:this.state.id+1})
	}
	

	playClicked()
	{
		if(!this.state.paused)
			this.setState({...this.state, paused:true})
		else
			this.setState({...this.state, paused:false})
	}


	componentDidMount()
	{
		if(this.videoRef.current)
		this.videoRef.current.addEventListener(
			'ended',
			function myHandler(e) {this.setState({...this.state, paused:true})}.bind(this),
			false);    
	}
	componentDidUpdate()
	{
		if(this.entries[this.state.id] !== "video")
			return;
		if(this.state.paused)
			this.videoRef.current.pause()
		else
			this.videoRef.current.play()
	}


	render()
	{
	
		let entry = this.entries[this.state.id];
		entry.url = i18n.t("introinfo."+entry.type+"."+entry.id+".url");
		entry.thumb = i18n.t("introinfo."+entry.type+"."+entry.id+".thumb");
		entry.title = i18n.t("introinfo."+entry.type+"."+entry.id+".title");

		
		return(
			<div id={this.props.id} className={"IntroInfo "+ this.props.className}>
				<div className="logo bgimg"></div>
				<div className="content">
					<div className="slides">
						<div className={"ar left bgimg" + (this.state.id<=0?" hidden":"")} onClick={()=>this.clicked(-1)}></div>
						<div className={"ar right bgimg" + (this.state.id>=this.entries.length-1?" hidden":"")} onClick={()=>this.clicked(1)}></div>
						<div className="content">
							{
								entry.type === "video"?
								<>
									<video ref={this.videoRef} poster={entry.thumb}  controls playsInline>
										<source src={entry.url} type="video/mp4" />
									</video>
									<div className={"ctrl_btn ia hidden bgimg " + (this.state.paused?"":" pause")}onClick={this.playClicked}></div>
								</>
								:
								<>
									<a target="_blank" rel="noopener noreferrer" href={entry.url}>
									<img src={entry.thumb} alt="" />
									</a>
									
								</>
							}
							
						</div>
					</div>
				
					<div className="title">{entry.title}</div>
					<div className={"dots" +(this.entries.length<=1?" hidden":"")}>
						<div className={this.state.id===0?"active":""}><div></div></div>
						<div className={this.state.id===1?"active":""}><div></div></div>
					</div>
				</div>
							
						
			</div>		
		);
	}
}
export default IntroInfo;