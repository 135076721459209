import React, {PureComponent} from 'react';
import "./ContentPrices.css";
import i18n from 'i18n';

class ContentPrices extends PureComponent
{
	getRow(item, prop)
	{
		if(!item[prop].Get())
			return(null);		
		return(	
		<tr>
			<td>{i18n.t("product.prices.table.head."+prop)}</td>
			<td>{item[prop].Get()}</td>
		</tr>
		)
	}

	render()
	{
		return(
			<div className="ContentPrices">
				<h1>{i18n.t("product.prices.title.label")}</h1>
				<div className="hr"></div>
				<table className="packages">
					<tbody>
					{this.getRow(this.props.model, "delivery_date")}
					{this.getRow(this.props.model, "price_marginal_costs")}
					{this.getRow(this.props.model, "price_out")}
					{this.getRow(this.props.model, "price_contribution_margin")}
					{this.getRow(this.props.model, "price_evp")}
					{this.getRow(this.props.model, "price_trading_margin")}
					</tbody>
				</table>
			</div>
		);
	}
}
export default ContentPrices;