import {loadLocalDataFunc, forceSyncData as _forceSyncData} from './../actions/app';

export function _doAppRequest(actionFunc, data)
{
	return dispatch => {
		dispatch(actionFunc(data));					
	};
}


export function loadLocalData(data) {
	return dispatch => {	 	
		dispatch(loadLocalDataFunc(data));
	 };
}
export function forceSyncData(data) {
	return dispatch => {	 	
		dispatch(_forceSyncData(data));
	 };
}

