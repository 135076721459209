export const APP_MESSAGE_TYPE_INFO = 'APP_MESSAGE_TYPE_INFO';
export const APP_MESSAGE_TYPE_WARNING = 'APP_MESSAGE_TYPE_WARNING';
export const APP_MESSAGE_TYPE_ERROR = 'APP_MESSAGE_TYPE_ERROR';
export const APP_MESSAGE_TYPE_CLEAR = 'APP_MESSAGE_TYPE_CLEAR';
export const APP_MESSAGE_TYPE_TODO = 'APP_MESSAGE_TYPE_TODO';

export const APP_MESSAGE_ADD = 'APP_MESSAGE_ADD';
export const APP_MESSAGE_REMOVE = 'APP_MESSAGE_REMOVE';

export const ALERT_MESSAGE_TYPE_SHOW = 'ALERT_MESSAGE_TYPE_SHOW';
export const ALERT_MESSAGE_TYPE_HIDE = 'ALERT_MESSAGE_TYPE_HIDE';

export const SET_HEADER_TYPE = 'SET_HEADER_TYPE';

export const LOAD_LOCAL_DATA = 'LOAD_LOCAL_DATA';
export const FORCE_SYNC_DATA = 'FORCE_SYNC_DATA';

export function loadLocalDataFunc(data) {return {type: LOAD_LOCAL_DATA, data: data};}
export function forceSyncData() {return {type: FORCE_SYNC_DATA};}

export function setHeaderAction(data) {	

	return {
		 type: SET_HEADER_TYPE,
		 data: data
	};
}

export function appMessageAdd(title, text, type) {	
	return {
		 type: APP_MESSAGE_ADD,
		 
		 message:{title:title, text:text, type:type}
	};
}
export function appMessageRemove(key) {	
	return {
		 type: APP_MESSAGE_REMOVE,
		 key:key
	};
}

export function alertMessageShow(msg) {	
	return {
		 type: ALERT_MESSAGE_TYPE_SHOW,	
		 message: msg 
	};
}
export function alertMessageHide(type) {	

	return {
		 type: ALERT_MESSAGE_TYPE_HIDE,
		 company_id: null
	};
}


