
import {RESPONSE_TYPE} from './../api/_api.js';

export const PRESENTATION_GET_PENDING = 'PRESENTATION_GET_PENDING';
export const PRESENTATION_GET_SUCCESS = 'PRESENTATION_GET_SUCCESS';
export const PRESENTATION_GET_ERROR = 'PRESENTATION_GET_ERROR';

export const PRESENTATION_CREATE_PENDING = 'PRESENTATION_CREATE_PENDING';
export const PRESENTATION_CREATE_SUCCESS = 'PRESENTATION_CREATE_SUCCESS';
export const PRESENTATION_CREATE_ERROR = 'PRESENTATION_CREATE_ERROR';

export const PRESENTATION_DUPLICATE_PENDING = 'PRESENTATION_DUPLICATE_PENDING';
export const PRESENTATION_DUPLICATE_SUCCESS = 'PRESENTATION_DUPLICATE_SUCCESS';
export const PRESENTATION_DUPLICATE_ERROR = 'PRESENTATION_DUPLICATE_ERROR';

export const PRESENTATION_UPDATE_PENDING = 'PRESENTATION_UPDATE_PENDING';
export const PRESENTATION_UPDATE_SUCCESS = 'PRESENTATION_UPDATE_SUCCESS';
export const PRESENTATION_UPDATE_ERROR = 'PRESENTATION_UPDATE_ERROR';

export const PRESENTATION_DELETE_PENDING = 'PRESENTATION_DELETE_PENDING';
export const PRESENTATION_DELETE_SUCCESS = 'PRESENTATION_DELETE_SUCCESS';
export const PRESENTATION_DELETE_ERROR = 'PRESENTATION_DELETE_ERROR';

export const PRESENTATION_SETUSER_PENDING = 'PRESENTATION_SETUSER_PENDING';
export const PRESENTATION_SETUSER_SUCCESS = 'PRESENTATION_SETUSER_SUCCESS';
export const PRESENTATION_SETUSER_ERROR = 'PRESENTATION_SETUSER_ERROR';

export const MODAL_PRESENTATION_GET_PENDING = 'MODAL_PRESENTATION_GET_PENDING';
export const MODAL_PRESENTATION_GET_SUCCESS = 'MODAL_PRESENTATION_GET_SUCCESS';
export const MODAL_PRESENTATION_GET_ERROR = 'MODAL_PRESENTATION_GET_ERROR';


export const PRESENTATION_SETACTUAL = 'PRESENTATION_SETACTUAL';





export function fetchGetModalPresentation(type, info, payload) {
	
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: MODAL_PRESENTATION_GET_PENDING, payload:info};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: MODAL_PRESENTATION_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: MODAL_PRESENTATION_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}


export function fetchGetPresentations(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: PRESENTATION_GET_PENDING, payload:info};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: PRESENTATION_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: PRESENTATION_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}



export function fetchDuplicatePresentation(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: PRESENTATION_DUPLICATE_PENDING, payload:info};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: PRESENTATION_DUPLICATE_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: PRESENTATION_DUPLICATE_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function fetchCreatePresentation(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: PRESENTATION_CREATE_PENDING, payload:info};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: PRESENTATION_CREATE_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: PRESENTATION_CREATE_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function fetchUpdatePresentation(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: PRESENTATION_UPDATE_PENDING, payload:info};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: PRESENTATION_UPDATE_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: PRESENTATION_UPDATE_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function fetchDeletePresentation(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: PRESENTATION_DELETE_PENDING, payload:info};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: PRESENTATION_DELETE_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: PRESENTATION_DELETE_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function fetchSetPresentationUser(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: PRESENTATION_SETUSER_PENDING, payload:info};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: PRESENTATION_SETUSER_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: PRESENTATION_SETUSER_ERROR,
			info: info,
			payload: payload
		};
	}
}

export function setActualPresentationAction(uuid) {	
	return {
		 type: PRESENTATION_SETACTUAL,		 
		 value:uuid
	};
}