import React, {Component} from 'react';

import "./ContentVideo.css";
import {GetProductDataUrl} from '_redux/api/_api';
import {IsPortrait} from 'Utils';
import QrCodeImage from 'ui/QrCodeImage';
class ContentVideo extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {paused:false}
		this.videoRef = React.createRef();
		this.playClicked = this.playClicked.bind(this);
		this.onMetaData = this.onMetaData.bind(this);
		this.onEnded = this.onEnded.bind(this);
	}

	onEnded(e)
	{		
		if(this.props.onVideoEnded)
			this.props.onVideoEnded();
	}

	onMetaData(e)
	{
		if(this.props.onVideoLength)
			this.props.onVideoLength(this.videoRef.current.duration);
	}

	playClicked()
	{
		if(!this.state.paused)
			this.setState({paused:true})
		else
			this.setState({paused:false})
	}


	componentDidMount()
	{
		this.videoRef.current.addEventListener(
			'ended',
			function myHandler(e) {this.setState({paused:true})}.bind(this),
			false);
	}
	componentDidUpdate()
	{
		if(this.state.paused)
			this.videoRef.current.pause()
		else
			this.videoRef.current.play()
	}
	render()
	{
		if(this.props.model == null || this.props.model.videos.length===0)
			return(<div className="ContentVideo"></div>);
		
		return(
			<div className="ContentVideo">
				<video autoPlay muted ref={this.videoRef} playsInline onLoadedMetadata={this.onMetaData} onEnded={this.onEnded} onPlay={this.props.onPlay}>
					<source src={ GetProductDataUrl(this.props.model, this.props.model.videos[0])} type="video/mp4" />
				</video>
				<div className={"ctrl_btn" + (this.state.paused?"":" pause")}onClick={this.playClicked}></div>
				{
					!IsPortrait() && this.props.qrcodeLink?
					<div className="qrArea">
						{this.props.qrcodeLink!=null?
							<QrCodeImage url={this.props.qrcodeLink} />
							:""
						}
					</div>

					:null
				}
			</div>			
		);
	}
}

export default ContentVideo;