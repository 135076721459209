import fetch from './_api_fetchTimeout';
import {apiRequestStart, apiRequestDone} from './../actions/api';
import i18n from '../../i18n';

/*const _API_URL_LOCAL = 'http://localhost/';//'http://localhost:8080/';
const _API_URL_DEV = 'https://api.stage.produktwelt-lambertz.de/';
const _API_URL = 'https://api.produktwelt-lambertz.de/';
*/
const _API_KEY = process.env.REACT_APP_API_KEY;
const _API_VERSION = '';
let _API_TOKEN = '';


export function GetProductDataUrl(product, basefile="", size=null)
{

	if(basefile == null)
		return "";
	if(basefile.indexOf("_dummy")>-1)
  {
    if(basefile.indexOf("menu_img.jpg")>-1)
      return GetApiURL()+"/"+basefile.split("menu_img").join("product_img");
		return GetApiURL()+"/"+basefile;
  }
	if(size == null)
		return GetApiURL()+"data/"+product.product_id.Get()+"/"+basefile;
	else
	{
		let f = basefile.split(".");
		return GetApiURL()+"data/"+product.product_id.Get()+"/"+f[0]+"_"+size+"."+f[1];
	}
}
export function GetGroupDataThumbUrl(group)
{
	return GetApiURL()+"groupData/"+group.internal_id+"_th_256.png";
}

export function GetDefaultGroupDataThumbUrl()
{
	return GetApiURL()+"_dummy/group_256.png";
}
export function GetDefaultProductDataUrl()
{
	return GetApiURL()+"_dummy/product_img_256.jpg";
}


export const Endpoints = {

	"login":{"id":"100", "path":"auth/login", "method":"POST"},
	"logout":{"id":"101", "path":"auth/logout", "method":"POST"},
	"register":{"id":"102", "path":"auth/register", "method":"POST"},
	"requestreset":{"id":"103", "path":"auth/reset", "method":"POST"},
	"reset":{"id":"104", "path":"auth/reset", "method":"POST"},
	"auth":{"id":"105", "path":"auth/refresh", "method":"POST"},
	"me":{"id":"106", "path":"auth/me", "method":"POST"},
	"user_update":{"id":"107", "path":"auth/update_user", "method":"POST"},


	"domain_get":{"id":"231", "path":"domain", "method":"POST"},
	"domain_setUserRole":{"id":"232", "path":"domain/set_user_role", "method":"POST"},
	"domain_loadUsers":{"id":"233", "path":"domain/get_users", "method":"POST"},
	"domain_deleteUser":{"id":"234", "path":"domain/delete_user", "method":"POST"},


	"product_get":{"id":"221", "path":"product/get", "method":"POST"},
	"product_import_status":{"id":"224", "path":"product/importstatus", "method":"POST"},
	"product_import":{"id":"222", "path":"product/import", "method":"POST"},
	"product_import_productimages":{"id":"225", "path":"product/import_data", "method":"POST"},
	"product_import_groupimages":{"id":"226", "path":"product/import_groupdata", "method":"POST"},


	"presentation_get":{"id":"211", "path":"presentation/get", "method":"POST"},
	"presentation_create":{"id":"212", "path":"presentation/create", "method":"POST"},
	"presentation_update":{"id":"213", "path":"presentation/update", "method":"POST"},
	"presentation_delete":{"id":"214", "path":"presentation/delete", "method":"POST"},
	"presentation_setuser":{"id":"215", "path":"presentation/add_user", "method":"POST"},
	"presentation_get_modal":{"id":"216", "path":"presentation/get_modal", "method":"POST"},

	"presentation_duplicate":{"id":"217", "path":"presentation/duplicate", "method":"POST"},


};

export const RESPONSE_TYPE= {SUCCES : 'SUCCES',ERROR:'ERROR',PENDING:'PENDING'};

export function GetApiURL()
{

	//return _API_URL_LOCAL;
	return process.env.REACT_APP_API_URL
  return _API_URL_DEV;
  if((document.location+"").indexOf("localhost")>-1)
    return _API_URL_LOCAL;//_API_URL_LOCAL;
  if((document.location+"").indexOf("stage.produktwelt-lam")!==-1)
		return _API_URL_DEV;
	if((document.location+"").indexOf("produktwelt-lam")!==-1)
		return _API_URL;
	return _API_URL_DEV;
}

//return _doRequest(Endpoints.login, fetchLogin, data);
export function _doRequest(endpoint, actionFunc, data)
{
	let debug = false;
	//if(endpoint.id === "000104")
	//	debug = true;

	var ob = _createApiCallObject(endpoint, data);

	return dispatch => {

		if(debug)console.log(endpoint, data, ob);



    if(endpoint.id != "221")//hide loader while loading products
    {
      dispatch(apiRequestStart());
		  dispatch(actionFunc(RESPONSE_TYPE.PENDING, data));
    }
		let timeout = 5000;
		if(ob.url.indexOf("/import")!==-1)
				timeout = 350000;

		fetch(ob.url, ob.data, timeout)
    .then(res => {
				if(debug)console.log(res);
				return res.json();
			})
    .then(res => {


			if(debug)console.log(res);
			if(res.success===false)
        throw(res);
			if(res.success===true)
			{
			//	console.log( res.info.id, id);
				if(!res.data)
					res.data = {};
				if(res.token)
					res.data.token = res.token;
				if(res.info!=null && res.info.id === endpoint.id)
				{
					dispatch(actionFunc(RESPONSE_TYPE.SUCCES, res.info, res.data));
				}
			}
			else
				dispatch(actionFunc(RESPONSE_TYPE.ERROR, res.info, res.data));
			return true;
    })
    .catch((error) => {
			if(debug)console.log(error);
		  dispatch(actionFunc(RESPONSE_TYPE.ERROR, error));
		})
		.finally(()=>	dispatch(apiRequestDone()));
		};

}

export function _createApiCallObject(endpoint, bodydata) {
	var o = {};
	o.url = GetApiURL()+_API_VERSION;

	o.headers = {"x-api-key": _API_KEY, 'Content-Type': 'application/json', "language":i18n.language , "Authorization": "Bearer "+_getToken()};
	o.method = "POST";
	o.body = {};
	__setAction(endpoint, o, bodydata);
	o.data = {'headers':o.headers};
	if(Object.keys(o.body).length)
		o.data.body = JSON.stringify(o.body);
	o.data.method = o.method;
	if(o.body.get!=null)
	{
		o.body = JSON.stringify(o.body);
		o.async = true;
  	o.crossDomain = true;
		o.contentType = false;
		o.processData = false;
		//o.data = o.body;

		o.data = {'headers':o.headers};
		o.data.method = o.method;
		o.redirect='follow';

		//o.body = null;
	//	o.mimeType = "multipart/form-data";
	}


	return o;
}

export function _createActionResult(scope, type, info, payload)
{
	return  {scope:scope, type:type, info:info!=null?info:null, payload:payload!=null?payload:null};
}


function __setAction(endpoint, o, bodydata)
{

	o.url += endpoint.path;
	o.method = endpoint.method;
	o.redirect = 'follow';
	// eslint-disable-next-line
	for(let i in Endpoints)
	{
		if(Endpoints[i].id === endpoint.id)
		{
			/*
			if(endpoint.id === "project_update")
			{
				var formData = new FormData();
				for(let s in bodydata)// eslint-disable-line
					formData.append(s, bodydata[s]);// eslint-disable-line
			}
			*/

		}
	}

//	if(_API_TOKEN!= null && _API_TOKEN.length>4)
//		o.url += "?nocache="+_API_TOKEN.slice(-3);
//	else
	o.url += "?nocache="+(new Date().getTime() / 1000);

	o.body = bodydata;
	if(o.body == null)
		o.body = {};
}

export function _getToken()
{

	if(_API_TOKEN==null || _API_TOKEN.length === 0)
		_API_TOKEN = localStorage.getItem("token");
	return _API_TOKEN;
}


export function _setToken(token)
{
	_API_TOKEN = token;
	localStorage.setItem("token", _API_TOKEN);
}

