import { ParseProduct } from "./Product";

export function ParsePresentations(raw)
{
	let presentations = [];
	if(Array.isArray(raw))
	{
		//eslint-disable-next-line
		for(let i in raw)
		{
			presentations.push(ParsePresentation(raw[i]));
		}
	}
	else if(raw != null)
		presentations.push(ParsePresentation(raw));

	
	return presentations;
}

export function UpdatePresentationInList(presentation,presentations)
{
	for(let i=0; i<presentations.length;i++)
	{
		if(presentations[i].uuid === presentation.uuid)
		{
			presentations[i] = presentation;
			return presentations;
		}
	}

	presentations.push(presentation);
	return presentations;

}

export function DeletePresentationInList(presentation,presentations)
{
	for(let i=0; i<presentations.length;i++)
	{
		if(presentations[i].uuid === presentation.uuid)
		{
			presentations.splice(i, 1);
			return presentations;
		}
	}

	return presentations;
}

export function SearchPresentation(uuid, presentations)
{
	for(let i=0; i<presentations.length;i++)
	{
		if(presentations[i].uuid === uuid)
			return presentations[i];		
	}	
	return null;
}

export function ParseModalPresentation(raw)
{
	let modalPresentation = ParsePresentation(raw);
	if(!Array.isArray(	raw.products ))
		raw.products = [];
		modalPresentation.products = [];
	for(let i=0; i<raw.products.length;i++)
	{
		let p = ParseProduct(raw.products[i]);
		if(p)
			modalPresentation.products.push(p);
	}
	return modalPresentation;
}
export function ParsePresentation(raw)
{
	let presentation = new Presentation();
	presentation.uuid = raw.uuid;
	presentation.name = raw.name;
	presentation.description = raw.description;
	presentation.public_hash = raw.public_hash;
	presentation.roleValue = raw.roleValue;
	presentation.users = raw.users;
	presentation.products = raw.products;
	if(!Array.isArray(	presentation.products ))
		presentation.products = [];
	presentation.updated_at = raw.updated_at;
	presentation.created_at = raw.created_at;
	presentation.readonly = raw.readonly;
	
	

	return presentation;
}

class Presentation{

}