import React, {Component} from 'react';


import i18n from 'i18n';
import { NavLink, Redirect } from 'react-router-dom';
import {GetId, GetSubId, IsPortrait} from 'Utils';
import { CONST } from 'Constants';
import {GetProductDataUrl} from '_redux/api/_api';
import{HasPrices} from '_redux/_models/Product'

import ContentInfo from 'ui/_presentation/ContentInfo/ContentInfo';
import Content3D from 'ui/_presentation//Content3D/Content3D';
import ContentVideo from 'ui/_presentation//ContentVideo/ContentVideo';
import ContentImage from 'ui/_presentation//ContentImage/ContentImage';
import PrintPortal from 'views/PrintPortal';

import {ParsePercent, GetTitle, GetArLink} from 'LambertzHelper';

import "./ProductDetailView.css";
import ContentPrices from '../../ui/_presentation/ContentPrices/ContentPrices';
import ProductDetailPrintView from '../ProductDetailPrintView/ProductDetailPrintView';


class ProductDetailView extends Component
{
	constructor(props)
	{
		super(props);
		this.ref = React.createRef();
		this.leftRef = React.createRef();
		this.switchScreen = this.switchScreen.bind(this);
		this.updateFullscreenIcon = this.updateFullscreenIcon.bind(this);
		this.printClicked = this.printClicked.bind(this);

	}

	printClicked(e)
	{
		window.print();
	}
	getTitle()
	{
		const sid = GetSubId(this.nested?1:0);
		if(sid === CONST._images)
			return i18n.t("product.modus.image");
		else if(sid === CONST._videos)
			return i18n.t("product.modus.video");
		else if(sid === CONST._3d)
			return i18n.t("product.modus.3d");
		else if(sid === CONST._info)
			return i18n.t("product.modus.info");
		else if(sid === CONST._prices)
			return i18n.t("product.modus.price");
	}

	isPrintEnabled()
	{
		const sid = GetSubId(this.nested?1:0);
		if(sid === CONST._info)
			return true;

		return false;
	}

	getLeftContent()
	{

		if(GetId() === CONST._empty)
			return null;


		const sid = GetSubId(this.nested?1:0);

	//	console.log(GetArLink(this.item));
		if(sid === CONST._images)
			return <ContentImage model={this.item} qrcodeLink={GetArLink(this.item)} ></ContentImage>
		else if(sid === CONST._videos)
			return <ContentVideo model={this.item} qrcodeLink={GetArLink(this.item)} onVideoEnded={this.props.onVideoEnded} onVideoLength={this.props.onVideoLength} onPlay={this.props.onPlay}></ContentVideo>
		else if(sid === CONST._3d)
			return <Content3D model={this.item} qrcodeLink={GetArLink(this.item)} ></Content3D>
		else if(sid === CONST._info)
			return <ContentInfo model={this.item} qrcodeLink={GetArLink(this.item)} products={this.props.products}></ContentInfo>;
		else if(sid === CONST._prices)
			return <ContentPrices model={this.item} qrcodeLink={GetArLink(this.item)}></ContentPrices>;

	}

	getTopContent()
	{
		if(GetId() === CONST._empty)
			return null;
		const sid = GetSubId(this.nested?1:0);
		if(sid === CONST._images)
			return <ContentImage model={this.item} qrcodeLink={GetArLink(this.item)} ></ContentImage>
		else if(sid === CONST._videos)
			return <ContentVideo model={this.item} qrcodeLink={GetArLink(this.item)} onVideoEnded={this.props.onVideoEnded} onVideoLength={this.props.onVideoLength}  onPlay={this.props.onPlay} ></ContentVideo>
		else if(sid === CONST._3d)
			return <Content3D model={this.item} qrcodeLink={GetArLink(this.item)} ></Content3D>
		else if(sid === CONST._prices)
			return <ContentPrices model={this.item} qrcodeLink={GetArLink(this.item)}></ContentPrices>;

	}
	getBottomContent()
	{
		const sid = GetSubId(this.nested?1:0);
		if(sid === CONST._info)
			return <ContentInfo model={this.item} qrcodeLink={this.item.link} products={this.props.products}></ContentInfo>;

		return(<div className="ingredients">
					{
					this.item.ingridient_keys.Get().length>1?
					this.item.ingridient_keys.Get().split("\n").map((d, index) => <p key={index + d}><span className="lab">{d}</span><span className="val">{ParsePercent(	this.item.ingridient_values.Get().split("\n")[index])}</span></p>)
					:
						null
					}
					</div>)
	}
	getItemSlide(_type)
	{
		if(this.item == null || !Array.isArray(this.item.slides))
			return null;

		for(let i=0;i<this.item.slides.length;i++)
			if(this.item.slides[i].type === _type)
				return this.item.slides[i];
		return null;
	}

	getRightContent()
	{
		if(GetId() === CONST._empty)
			return null;



		const sid = GetSubId(this.nested?1:0);

		if(sid === CONST._images || sid === CONST._videos || sid === CONST._3d || sid === CONST._prices)
		{

			const el = <div className="right">
				<div className="head">
					<div className="_1">
						<h1>{GetTitle(this.item)}</h1>
						<h2>{this.item.subrow.Get()}</h2>
						<h3>{i18n.t("product.info.article_no.label") + CONST._space+ this.item.product_id.Get()}</h3>
					</div>
					<div className="_2">
						{this.item.bio.Get()?<img src={"/images/icon_bio.png"} alt=""></img>:null}
					</div>
				</div>
				<div className="hr"></div>
				<div className="body">
					{this.item.ingridient_keys.Get() && this.item.ingridient_keys.Get().length>1?
					<div className="ingredients">
						{this.item.ingridient_keys.Get().split("\n").map((d, index) =><p key={index + d}><span className="lab">{d}</span><span className="val">{ParsePercent(this.item.ingridient_values.Get().split("\n")[index])}</span></p>)}
					</div>
					:null}
				</div>

				<div className="foot">
					<div className="certs">
						<h2>{i18n.t("product.info.certificates.label")}</h2>
						<div className="hr"></div>
						<div className="icons">
						{this.item.organic_certification?.Get()?<div className="organic_certification">{this.item.organic_certification.Get()}</div>:null}
						{this.item.bio.Get()?<img src={"/images/icon_bio.png"} alt=""></img>:null}
						{this.item.vegan.Get()?<img src={"/images/icon_vegan.png"} alt=""></img>:null}
						{this.item.fairtrade.Get()?<img src={"/images/icon_fairtrade.png"} alt=""></img>:null}

						{this.item.geo.Get().toUpperCase().indexOf("AACHENER")>-1?<img src={"/images/icon_aacher_printen.png"} alt=""></img>:null}
						{this.item.geo.Get().toUpperCase().indexOf("NÜRNBERGER")>-1?<img src={"/images/icon_neurnberger_lebkuchen.png"} alt=""></img>:null}

						</div>
						<img className="badge" src={GetProductDataUrl(this.item, this.item.badge_img)} alt={CONST._empty}></img>
						<div className="bgimg"></div>
					</div>


				</div>
			</div>;
			return el;
		}
		else if(sid === CONST._info)
		{
			return null;//<><div className="ing_img" style={{backgroundImage:'url(./content/'+this.item.ingredient_img_03+')'}}></div></>;
		}
		return null;
	}

	/*#region Fullscreen*/
	switchScreen()
	{
		if(this.isFullscreen())
			this.closeFullscreen();
		else
			this.openFullscreen(this.ref.current)
	}

	isFullscreen(){ return document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen};
	openFullscreen(elem) {
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) { /* Firefox */
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
			elem.webkitRequestFullscreen();
		} else if (elem.msRequestFullscreen) { /* IE/Edge */
			elem.msRequestFullscreen();
		}

		this.updateFullscreenIcon();
	}

	closeFullscreen() {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) { /* Firefox */
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) { /* IE/Edge */
			document.msExitFullscreen();
		}

		this.updateFullscreenIcon();
	}
	updateFullscreenIcon()
	{
		if(!this.ref.current)
			return;
		if(this.isFullscreen())
			this.ref.current.classList.add("fullscreen");
		else
			this.ref.current.classList.remove("fullscreen");

		this.updateSize();
	}

	/*#endregion Fullscreen*/

	componentDidMount()
	{
		this.updateSize();
		document.addEventListener("fullscreenchange",	this.updateFullscreenIcon);
	}
	componentDidUpdate()
	{
		this.updateSize();
	}

	updateSize()
	{
		if(this.ref.current==null)
			return;
		if(16/9<this.leftRef.current.clientWidth/this.leftRef.current.clientHeight)
		{
			this.ref.current.classList.add("wide");
		}
		else
			this.ref.current.classList.remove("wide");
	}

	getPrintProtal(item)
	{
		return(<PrintPortal>
			<div className="p" id={"print_0"}>
				<ProductDetailPrintView data={item}></ProductDetailPrintView>
			</div>
		</PrintPortal>);
	}

	getBadges(item)
	{
		return(
			<div className="badges">
			{item.organic_certification?.Get()?<div className="organic_certification">{item.organic_certification.Get()}</div>:null}
			{item.bio.Get()?<img src={"/images/icon_bio.png"} alt=""></img>:null}
			{item.vegan.Get()?<img src={"/images/icon_vegan.png"} alt=""></img>:null}
			{item.fairtrade.Get()?<img src={"/images/icon_fairtrade.png"} alt=""></img>:null}

			{item.geo.Get().toUpperCase().indexOf("AACHENER")>-1?<img src={"/images/icon_aacher_printen.png"} alt=""></img>:null}
			{item.geo.Get().toUpperCase().indexOf("NÜRNBERGER")>-1?<img src={"/images/icon_neurnberger_lebkuchen.png"} alt=""></img>:null}
		</div>
		)
	}

	getPortraitInfoImage(item)
	{

	//	GetProductDataUrl(product, basefile="", size=null)

		if(GetId() === CONST._empty)
			return null;
		const sid = GetSubId(this.nested?1:0);
		if(sid === CONST._info)
			return 	<div className="mobileimg"><img src={GetProductDataUrl(item, "mobile_1024.png")} alt={item.name} /></div>

		return null;
	}
	render()
	{
		if(this.props.data == null)
			return("..loading");
		if(this.props.nestedInPresentation)
			this.nested = true;



		this.item = this.props.data;


		console.log(this.item);
		if(this.item == null)
			return("");

		let linkRoot = CONST.pathes.product+this.item.uuid+CONST._slash;
		if(this.props.linkRoot)
			linkRoot = this.props.linkRoot
	//	console.log(this.item.images);
		const sid = GetSubId(this.nested?1:0);
		if(sid == null || sid === CONST._empty)
			return <Redirect to={linkRoot+CONST._videos}></Redirect>

		if(IsPortrait())
		{
			return(
				<div className={"ProductDetailView" +(this.nested?" nested":"")} ref={this.ref} >

					<div className="left" ref={this.leftRef} >
						<div className="_phead">
							<div>
								<h1>{GetTitle(this.item)}</h1>
								<h2>{this.item.subrow.Get()}</h2>
								<h3>{i18n.t("product.info.article_no.label") + CONST._space+ (this.item.product_id.Get?this.item.product_id.Get():this.item.product_id)}</h3>
							</div>

						</div>
						{this.getPortraitInfoImage(this.item)}
						<div className="hr"></div>
						{this.getTopContent()}
					</div>
					<div className="stageNavigation">
						<div className="nav nav1">
							{this.item.videos.length>0?<NavLink className={CONST._videos} to={linkRoot+CONST._videos}><div></div></NavLink>:null}
							{this.item.images.length>0?<NavLink className={CONST._images} to={linkRoot+CONST._images}><div></div></NavLink>:null}
							<NavLink className={CONST._info} to={linkRoot+CONST._info}><div></div></NavLink>
							{HasPrices(this.item)?<NavLink className={CONST._prices} to={linkRoot+CONST._prices}><div></div></NavLink>:null}
							{this.item._3d?<NavLink className={CONST._3d} to={linkRoot+CONST._3d}><div></div></NavLink>	:null}
						</div>
					</div>
					<div className="fbottom">
						{this.getBottomContent()}
						{this.getBadges(this.item)}
					</div>
					{this.getPrintProtal(this.item)}
				</div>
			);
		}
		return(
			<div className={"ProductDetailView" +(this.nested?" nested":"")} ref={this.ref} >
				<div className="content">
					<div className="left" ref={this.leftRef} >
						<div className="stage">{this.getLeftContent()}</div>
					</div>
				{this.getRightContent()}
				</div>
				<div className="stageNavigation">
					<div className="nav nav1">
						{this.item.videos.length>0?<NavLink className={CONST._videos} to={linkRoot+CONST._videos}><div></div></NavLink>:null}
						{this.item.images.length>0?<NavLink className={CONST._images} to={linkRoot+CONST._images}><div></div></NavLink>:null}
						<NavLink className={CONST._info} to={linkRoot+CONST._info}><div></div></NavLink>
						{HasPrices(this.item)?<NavLink className={CONST._prices} to={linkRoot+CONST._prices}><div></div></NavLink>:null}
						{this.item._3d?<NavLink className={CONST._3d} to={linkRoot+CONST._3d}><div></div></NavLink>	:null}
					</div>

					<div className="nav nav2">
						{this.isPrintEnabled()?<div className={"print bgimg  ia"} onClick={this.printClicked}><div></div></div>:null}
						<div className="fullscreen bgimg ia" onClick={this.switchScreen}><div></div></div>
					</div>

				</div>
			<div className="bottom"><span>{this.getTitle()}</span></div>
			{this.getPrintProtal(this.item)}
			</div>

		);
	}
}
export default ProductDetailView;
