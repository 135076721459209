import React, {Component} from 'react';


import "./BackButton.css";

class BackButton extends Component
{
	render()
	{
		return(
			<div 
				onClick={this.props.onClick!=null?this.props.onClick:()=>{}} 
				className={"BackButton bgimg ia"+ (this.props.className!=null?" "+this.props.className:"")+(this.props.disabled?" disabled":"")}>
				{this.props.children}
			</div>			
		);
	}
}
export default BackButton;
