import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import i18n from 'i18n';

import {ValidateEmail} from 'Utils';
import {GetError} from 'Errors';
import {addAppMessage} from '_redux/api/appMessage';
import {passResetRequest} from '_redux/api/login';
import {APP_MESSAGE_TYPE_ERROR, APP_MESSAGE_TYPE_CLEAR} from '_redux/actions/app';
import {getPassResetRequestDone, getPassResetRequestError, getPassResetRequestPending} from '_redux/reducers/login';

import TextInput from './../assets/TextInput';
import {Link, Redirect} from 'react-router-dom';
import "./CardLogin.css";
import { CONST } from '../../Constants';
import { APP_MESSAGE_TYPE_INFO } from '../../_redux/actions/app';

class CardLoginPassForgot extends Component
{
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.handleRegister = this.handleRegister.bind(this);
		this.state = {user:{val:""}};	
	}

	handleRegister(){this.setState({...this.state, redirect:'/login/register'})}

	handleClick() {
		this.props.addAppMessage(CONST._empty, CONST._empty, APP_MESSAGE_TYPE_CLEAR);
		
		if (!ValidateEmail(this.state.user.val)) { 
			this.setState({error:true})
			setTimeout(function() { 
				var err = GetError({id:"app001"});
				this.props.addAppMessage(err.id, err.msg, APP_MESSAGE_TYPE_ERROR);				
			}.bind(this), 50);			
		}
		else
		{
			this.setState({error:false})		
			this.props.passResetRequest({"email":this.state.user.val});
		}

		
  }

	onSubmit(event){
		event.preventDefault();
		this.handleClick();
	}


	componentDidUpdate()
	{
		if(this.state.redirect)
			this.setState({...this.state, redirect:null})		
	}

	render()
	{
		if(this.state.redirect)
		{
			return(<Redirect to={this.state.redirect}></Redirect>)
		}
		if(this.state == null)
		{
	//		this.setState({user:{val:""}});	
	//		return("");		
		}
		const {result, error, pending} = this.props;
		if(result==null && error == null && pending == null)
		{
		
		}
		else if(!pending)
		{
			/*if(error != undefined)
				console.log("pass reset Error", error)
			if(result)
				console.log("pass reset");
				*/
		}
		if(this.props.result)
		{
			this.props.addAppMessage("", i18n.t("login.forgot.info_done"), APP_MESSAGE_TYPE_INFO);				
		}

		return(
			<div className="Login Card round">
				<div className="Card-title Login-title">
					<span>{i18n.t("login.forgot.title")}</span>
				</div>
				<div className="Card-content Login-content">
					<form onSubmit={this.onSubmit}>
					<span className="text">{i18n.t("login.forgot.info")}</span>
					<TextInput label={i18n.t("login.forgot.name.label")} placeholder={i18n.t("login.forgot.name.placeholder")} type="text" model={this.state.user} error={this.state.error} editable={true}></TextInput>					
					<div className="btns">
						<div onClick={this.handleClick} className="login_btn lmbrtz_btn">{i18n.t("login.forgot.send_btn")}</div>
						<input type="submit" className="hidden"></input>
						<span>{i18n.t("login.register.text")}</span>
						<br/>
						<div onClick={this.handleRegister} className="register_btn lmbrtz_btn red">{i18n.t("login.register.link")}</div>
						
						<Link className="link" to="/login">{i18n.t("login.back_login.link")}</Link>
					</div>
					</form>
				</div>
			</div>
		);
		}
	
}

const mapStateToProps = state => ({
	result: getPassResetRequestDone(state),
	error: getPassResetRequestError(state),
	pending: getPassResetRequestPending(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({passResetRequest: passResetRequest, addAppMessage: addAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CardLoginPassForgot);

