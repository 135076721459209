import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {getGroups as groups} from '_redux/reducers/domain';

import "./SearchInput.css";
import { Redirect, withRouter } from 'react-router-dom';
import { CONST } from 'Constants';

class SearchInput extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {needle:null}
		this.changed = this.changed.bind(this);
		this.keyDown = this.keyDown.bind(this);
		this.sendClicked = this.sendClicked.bind(this);
		this.needle = "";
	}

	sendClicked()
	{
		this.submit(this.needle);
	}

	changed(e)
	{
		this.needle = e.target.value;
		if(this.props.onChanged)
		{
			this.props.onChanged(e.target.value)
		}

		if(e.target.value.length>1)
		{
		//	let results = SearchEntries(e.target.value, this.props.groups);
		//	console.log(results);
		}
	
	}
	submit(val)
	{
		if(this.props.onChanged)
			return;
		
		this.setState({...this.state, needle:val})
	}
	keyDown(e)
	{		
		
		if(this.props.onChanged)
		{
			return;
		}
		if(e.keyCode === 13)
			this.submit(e.target.value);
	}

	componentDidUpdate()
	{
		if(this.state.needle != null)
		{			
			this.setState({...this.state, needle:null});
		}
	}
	render()
	{
		if(this.state.needle != null)
		{
			return (<Redirect to={CONST.pathes.search+this.state.needle}></Redirect>)
		}
		return(
			<div 
				onClick={this.props.onClick!=null?this.props.onClick:()=>{}} 
				className={"SearchInput"+ (this.props.className!=null?" "+this.props.className:"")+(this.props.type?" "+this.props.type:"")}>
				<input type="text" placeholder="" onInput={this.changed} onKeyDown={this.keyDown}></input>
			
				<div className="circle">
					<div className={"bg" +(this.props.showBG?"":" hidden")}></div>
					<div className="ico" onClick={this.sendClicked}></div>				
				</div>
			</div>			
		);
	}
}

const mapStateToProps = state => ({
	groups: groups(state)
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SearchInput));