import React from 'react';

import "./MainFooter.css";
import i18n from 'i18n';
import { Link } from 'react-router-dom';
import { CONST } from '../../Constants';
//import {version} from './../../../package.json';

import { withTranslation } from 'react-i18next';
class MainFooter extends React.PureComponent
{
	render()
	{
		return(
			<div className="MainFooter" >
				<span>{i18n.t("footer.copyright")}</span>
				<span className="d">{i18n.t("footer.devider")}</span>
				<Link to={CONST.pathes.contact}>{i18n.t("footer.contact")}</Link>
				<span className="d">{i18n.t("footer.devider")}</span> 
				<Link to={CONST.pathes.imprint}>{i18n.t("footer.imprint")}</Link>
				<span className="d">{i18n.t("footer.devider")}</span>
				<Link to={CONST.pathes.data_privacy}>{i18n.t("footer.data")}</Link>
        <span className="d">{i18n.t("footer.devider")}</span>
        <Link to={CONST.pathes.certificates}>{i18n.t("footer",{ returnObjects: true}).certificates}</Link>

				<a href="https://www.domeniceau.de/" rel="noopener noreferrer" target="_blank" className="watermark"><span className="d">{i18n.t("footer.devider")}</span>{i18n.t("footer.watermark")}</a>
				{<div className="version">{i18n.t("footer.verion",{version:process.env.REACT_APP_VERSION})}</div>}
			</div>		
		);
	}
}
export default withTranslation()(MainFooter);