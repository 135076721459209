export const CONST = {
	_empty:'',
	_slash:'/',
	_hash:'#',
	_space:' ',
	_colon:':',
	_asc:'asc',
	_desc:'desc',

  ios:'ios',
	android:'android',
	new:'new',
	del:'del',
	active: 'active',
  _active: ' active',
	presentation: 'presentation',
	presentations: 'presentations',
	_images: '_images',
	_videos: '_videos',
	_3d: '_3d',
	_info: '_info',
	_prices: '_prices',
	
  en:'en',
  de:'de',
	pathes:
	{
		home: '/',
		product: '/product/',
		presentation: '/presentation/',
		presentations: '/presentations/',
		search: '/search/',
		data_privacy: '/data_privacy/',
		contact: '/contact/',
		imprint: '/imprint/',
		usermanagement:'/usermanagement/',
		modal_presentation:'/p/',
		login: '/login/',
		loginSplash:'/login/splash',
		dataimport:'/dataimport/',
    certificates:'/certificates/'

	},
	_cookies:
	{
		essential:
		{
			settings:
			{
				"offline_allowed":"essential.settings.offline_allowed",
				"comfort_allowed":"essential.settings.comfort_allowed"
			},
			loggedout:"essential.loggedout",
			pendingLocation:"_cookies.essential.pendingLocation"
		},
		/*offline:
		{
			presentations:"essential.presentations",
			products_payload:"essential.products_payload",
			domains_payload:"essential.domains_payload"
			
		},*/
		comfort:
		{					
			token:"comfort.token",
			splashLoginLoaded: 'splashLoginLoaded',
      language:'comfort.language'
		}
	}

};