
import React, { Component } from 'react';
import {withRouter, Link} from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {setHeader} from '_redux/api/appMessage';
import {getGroups as groups} from '_redux/reducers/domain';

import './SearchResultsView.css';
import {GetId, IsPortrait} from 'Utils'
import { CONST } from '../../Constants';
import i18n from '../../i18n';
import {SearchEntries, GetTitle} from 'LambertzHelper';

import {GetProductDataUrl, GetGroupDataThumbUrl, GetDefaultProductDataUrl, GetDefaultGroupDataThumbUrl} from '_redux/api/_api';

class SearchResultsView extends Component {

	constructor(props)
	{
		super(props);

		this.productsRef = React.createRef();
	}

	componentDidUpdate()
	{

		if(IsPortrait())
			return;

		if(this.productsRef.current)
		{
			let w = this.productsRef.current.offsetWidth;
			let h = this.productsRef.current.offsetHeight;
			let borderwidth = getComputedStyle(this.productsRef.current).getPropertyValue('--border-width');
			let border = 0;
			if(borderwidth)
				border = parseInt(borderwidth.split("px").join(""));
			let columns = Math.floor(w/170);
			if(columns<2)
				columns = 2;
			if(columns>7)
				columns = 7;

			let itemw = (w / columns - border - .1)
			let itemw_ = (w / columns)
			if(h<Math.ceil(this.entrylength/columns)*itemw_)
			{
				w -= 20;
				itemw = (w / columns - border - .1)
			}


			this.productsRef.current.style.setProperty('--size', itemw + "px");
		}
	}


	render() {

		let val = GetId();
		val = decodeURI(val);
		let entries = [];
    if( this.props.groups){
      entries = SearchEntries(val, this.props.groups);
    }

		this.props.setHeader(
			{
				title:i18n.t("search.title", {results:entries.length}),
				subtitle:i18n.t("search.subtitle", {val:val.split("%20").join(" ")})
			}
		);
			//console.log(entries);
	//	this.entrylength = entries.length;
		return (
      <div className="SearchResultsView">
				{!IsPortrait()?
				<>
				<h1>{i18n.t("search.title", {results:entries.length})}</h1>
				{val.length<3?
					<h2>{i18n.t("search.error.val_to_short", {val:val.split("%20").join(" ")})}</h2>
				:
					<h2>{i18n.t("search.subtitle", {val:val.split("%20").join(" ")})}</h2>
				}
				<br></br>
				</>
				:null}
				<div className="products" ref={this.productsRef}>
					{entries.filter((e,i)=>{return entries.indexOf(e) == i;}).map((entry,i)=>
					{
						if(entry.thumb_midi)
							return(<Link to={CONST.pathes.product + entry.uuid} key={entry.uuid+"_"+i}>
                <img src={GetProductDataUrl(entry, entry.thumb_midi)} alt="" onError={(e)=>{e.target.src!=GetDefaultProductDataUrl()?e.target.src=GetDefaultProductDataUrl():null}}  onLoad={(e)=>{e.target.parentNode.classList.add("loaded")}}></img>
                <span>{GetTitle(entry)}</span>
              </Link>)
						else
							return(<Link to={CONST.pathes.product + entry.uuid} key={entry.uuid+"_"+i} className="group">
                <img src={GetGroupDataThumbUrl(entry)} alt="" onError={(e)=>{e.target.src!=GetDefaultGroupDataThumbUrl()?e.target.src=GetDefaultGroupDataThumbUrl():null}} onLoad={(e)=>{e.target.parentNode.classList.add("loaded")}}></img>
                <span>{entry.name.Get()}</span>
                </Link>)
					})
					}
				</div>
		  </div>
    );
  }
}

const mapStateToProps = state => ({
	groups: groups(state)
})
const mapDispatchToProps = dispatch => bindActionCreators({setHeader}, dispatch);
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SearchResultsView));