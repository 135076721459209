import React, {Component} from 'react';

import Icon from './../Icon';
import PropTypes from 'prop-types';

import "./Button.css";
import {Link} from 'react-router-dom';

class Button extends Component
{
	static propTypes = {
		children: PropTypes.string,
		className: PropTypes.oneOf(['black', 'gray', 'green'])
	};
	render()
	{
		var left = null;
		var right = null;
		if(this.props.left)
		{
			left = (
				<Icon type={this.props.left} status={this.props.status}></Icon>	
			);
		}
		if(this.props.right)
		{
			right = (
				<Icon type={this.props.right} status={this.props.status}></Icon>	
			);
		}

		return(
			<Link 
				onClick={this.props.onClick!=null?this.props.onClick:()=>{}} 
				id={this.props.id!=null?this.props.id:null} 
				to={this.props.to!=null?this.props.to:"#"} 
				title={this.props.title?this.props.title:null}
				className={"Button round" + (this.props.color!=null?" "+this.props.color:"") + (this.props.className!=null?" "+this.props.className:"")+(this.props.disabled?" disabled":"")}>
				{left}
				<span className="Button-content">{this.props.children}</span>				
				{right}
			</Link>
			
		);
	}
}

export default Button;



Button.propTypes = {
  status: PropTypes.any,
  left: PropTypes.string,
  to: PropTypes.string,
  color: PropTypes.string
}

