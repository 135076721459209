import {DynamicSort } from 'LambertzHelper';
import { CreateLanguageString } from './LanguageString';

export function ParseGroups(raw)
{
	let groups = [];
	if(raw == null)
		return groups;
	if(Array.isArray(raw))
	{
		//eslint-disable-next-line
		for(let i in raw)
			groups.push(ParseGroup(raw[i]));
	}
	else
		groups.push(ParseGroup(raw));	
	return groups;
}
export function AddDynamicGroup(groups, products, productKey, groupName="new_products_group_name", parentGroupId="GER")
{
	if(groups == null)
		return;
  
	try{
    
    let group = new Group();
    
    group.uuid = productKey;
    
    group.is_virtual = true;

    group.name = CreateLanguageString(groupName);
    group.internal_sort = 0;
    group.internal_id = parentGroupId+"_"+0;
    group.parent_id = parentGroupId;
    group.products = [];
    
    
    for(let i in groups){
      let _prs = getNewProductsInGroup(groups[i], productKey);
    
      group.products = group.products.concat(_prs);
    }
    
    
    group.products.sort(DynamicSort("name"));
    group.products.map((product,i)=>product.internal_sort=i);
    
    
    group.children = [];
    if(group.products.length>0)
      groups.push(group);	 
  }catch(ex)
  {
    console.log(ex)
  }
}

function getNewProductsInGroup(group, productKey, depth=0)
{
  let products = [];

  for(let i in group.products){
    if(group.products[i][productKey]){
      products.push(group.products[i]);      
    }
  }
  for(let i in group.children){
    products = products.concat(getNewProductsInGroup(group.children[i], productKey, depth+1));
  }
  
  return products;
}


export function MapProductsToGroups(groups, products, productMap=null)
{
	if(groups == null)
		return;
	if(productMap == null)
	{
		productMap = {};
		for(let i=0; i<products.length;i++)//eslint-disable-next-line
			productMap[products[i].uuid] = products[i];
	}
	// eslint-disable-next-line
	groups.map(function(group){
		for(let i=0; i<group.products.length;i++)
		{
			let product = productMap[group.products[i].product_id];
			if(product != null)
				group.products[i] = product;
		}
		MapProductsToGroups(group.children, products, productMap);
	});
}

let groupsMap = {};
let lastGroupsMap = [];
export function SearchGroup(uuid, groups)
{
	if(lastGroupsMap !== groups)
	{		
		lastGroupsMap = groups;
		groupsMap = {};
		createGroupsMap(groups, groupsMap);
	}
	
}

export function ParseGroup(raw)
{	
	let group = new Group();
	group.uuid = raw.uuid;
	group.name = CreateLanguageString(raw.name);
	group.is_virtual = false;
	group.internal_sort = raw.internal_sort;
	group.internal_id = raw.internal_id;
	//group.parent_id = raw.parent_id;

	group.products = raw.products;
	group.children = ParseGroups(raw.children);
	
	return group;
}

export class Group{
  
}

function createGroupsMap(groups, groupsMap)
{//eslint-disable-next-line
	groups.map(function(group)
		{
			groupsMap[group.uuid] = group;
			if(group.children)
				createGroupsMap(group.children, groupsMap);
		}
	);
}