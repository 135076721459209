import {APP_MESSAGE_ADD, APP_MESSAGE_REMOVE, ALERT_MESSAGE_TYPE_SHOW, ALERT_MESSAGE_TYPE_HIDE, SET_HEADER_TYPE} from './../actions/app';
import {initialState}  from 'initialState.js';

export function appReducer(state = initialState, action) {
  switch(action.type) {
		case APP_MESSAGE_ADD: 
    	return {
				...state,				
				messages: [action.message]
		}    
		case APP_MESSAGE_REMOVE: 
			action.key = parseInt(action.key);
			return {
				...state,
			messages: state.messages.filter(msg=>msg.key!==action.key)
		}   
 		case ALERT_MESSAGE_TYPE_SHOW: 		
			return {
			...state,
			alertMessage:  {type:ALERT_MESSAGE_TYPE_SHOW, message:action.message}
		}
		case ALERT_MESSAGE_TYPE_HIDE: 
			return {
			...state,
			alertMessage:  {type:ALERT_MESSAGE_TYPE_HIDE, message:null}
		}
		case SET_HEADER_TYPE: 
			return {
			...state,
			header: action.data
		}
			
			
    default: 
			return state;
	}
}

export const getAlertMessage = state => state.app.alertMessage;
export const getMessages = state => state.app.messages;
export const GetHeader = state => state.app.header;