import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {getMessages} from '_redux/reducers/app';
import {removeAppMessage} from '_redux/api/appMessage';
import "./MessageAlert.css";
import {APP_MESSAGE_TYPE_CLEAR} from '_redux/actions/app';
class MessageAlert extends Component
{
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick(e) {		
		this.props.removeAppMessage(e.currentTarget.dataset.index);
	}
	
	componentDidUpdate()
	{
		if(this.timer!=null)
			clearTimeout(this.timer);
	
		if(this.props.messages.length>0)
		{
			if(this.props.messages[this.props.messages.length-1].type === APP_MESSAGE_TYPE_CLEAR)
			{
				this.props.messages.map(msg => this.props.removeAppMessage(msg.key));
			}
			else
			{
				let msg = this.props.messages[this.props.messages.length-1];			
				this.timer = setTimeout(() => this.props.removeAppMessage(msg.key), 3000);
			}
		}
	}

	render()
	{
		
		/* eslint-disable */
		this.props.messages.map(msg => {msg.key = msg.key == null?msg.key=new Date().getTime():msg.key});
		/* eslint-enable */

		
		return(
			<div className="MessageAlert">
				{this.props.messages.map(msg=>
				{
					return (<div className={"msg " + msg.type} key={msg.key}>{msg.text}</div>);
				})
				}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	messages: getMessages(state)
})
const mapDispatchToProps = dispatch => bindActionCreators({removeAppMessage: removeAppMessage}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(MessageAlert);