import React, {Component} from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import "./Header.css";
import i18n from 'i18n';
import { Link, NavLink, Redirect, withRouter } from 'react-router-dom';
import Menu from '../Menu';
import UserDetails from '../UserDetails';
import BackButton from '../assets/BackButton';
import SearchInput from '../assets/SearchInput';
import {IsPortrait} from 'Utils';
import { CONST } from '../../Constants';

import {GetHeader} from '_redux/reducers/app';
import {getUser} from '_redux/reducers/login';
import {getActualDomain as actualDomain} from '_redux/reducers/domain';
import { withTranslation } from 'react-i18next';

class Header extends Component
{
	constructor(props)
	{
		super(props);
		this.state = {menu_opened:false, user_opened:false}
		this.hamburger_btn_clicked = this.hamburger_btn_clicked.bind(this);
		this.user_btn_clicked = this.user_btn_clicked.bind(this);
		this.drawerClosed= this.drawerClosed.bind(this);
		this.somewhere_else_clicked = this.somewhere_else_clicked.bind(this);
    this.langSwitch = this.langSwitch.bind(this);
	}
  langSwitch()
  {
    if(i18n.language === CONST.de){
      i18n.changeLanguage(CONST.en);
    }
    else{
      i18n.changeLanguage(CONST.de);     
    }
  }
	drawerClosed()
	{
		if(IsPortrait())
			this.hamburger_btn_clicked();
	}

	somewhere_else_clicked(e)
	{
		if(this.state.menu_opened)
		{		
			if(e.clientX<document.getElementById("drawer").offsetLeft)
			{
				document.removeEventListener("click", this.somewhere_else_clicked);
				this.setState({...this.state, menu_opened:false, user_opened:false});
			}
		}
		else  if(this.state.user_opened)
		{
			if(document.getElementById("user_drawer")!=null && e.clientX<document.getElementById("user_drawer").offsetLeft)
			{
				document.removeEventListener("click", this.somewhere_else_clicked);
				this.setState({...this.state, menu_opened:false, user_opened:false});
			}
		}
	}
	
	user_btn_clicked(e)
	{
		e.preventDefault();
		if(this.props.user == null)
		{
			document.removeEventListener("click", this.somewhere_else_clicked);
			this.setState({...this.state, user_opened:false,  menu_opened:false, redirect:"/login"});
			return;
		}
		if(this.state.user_opened)
			document.removeEventListener("click", this.somewhere_else_clicked);
		else
			document.addEventListener("click", this.somewhere_else_clicked);
		
		this.setState({...this.state, user_opened:true,  menu_opened:false});
	}

	componentWillUnmount()
	{
		document.removeEventListener("click", this.somewhere_else_clicked);
	}


	hamburger_btn_clicked(e)
	{
//		console.log(this.state.menu_opened)
	
		if(this.state.menu_opened)
			document.removeEventListener("click", this.somewhere_else_clicked);
		else
			document.addEventListener("click", this.somewhere_else_clicked);
	
		this.setState({...this, menu_opened:!this.state.menu_opened, user_opened:false});
	}

	componentDidUpdate()
	{
		if(this.state.redirect != null)
			this.setState({...this.state, redirect:null, user_opened:false,  menu_opened:false})
	}

	
	render()
	{		
		if(this.state.redirect != null)
			return(<Redirect to={this.state.redirect}></Redirect>)



		let username = "user name";
		if(this.props.user)
		{
			if((this.props.user.firstname + this.props.user.lastname).length>0)
				username = this.props.user.firstname + " " + this.props.user.lastname;
			else
				username = this.props.user.email;
		}
		
		
		if(this.historyLength == null)
		{
			this.historyLength = this.props.history.length;
			this.startHistoryLength = this.props.history.length;
		}
		else
		{
			if(this.props.history.action === "POP")
				this.historyLength--;
			else if(this.props.history.action === "PUSH")
				this.historyLength++;

			if(this.historyLength<this.startHistoryLength)
				this.historyLength = this.startHistoryLength
		}
		const backVisible = this.historyLength>this.startHistoryLength;
		let isLoginView = this.props.location.pathname.indexOf("/login")>-1;
		
		return(
			<div className={"Header"+(isLoginView?" login":"")} >
				<div className="c">
				{!IsPortrait() || isLoginView?<Link to="/" className="logo bgimg"></Link>:null}
				<div className="right">
					{backVisible?<BackButton onClick={this.props.history.goBack}>{IsPortrait()?null:i18n.t("header.back_btn")}</BackButton>:null}
					<SearchInput></SearchInput>
          <div className="langSelect noselect ia" onClick={this.langSwitch}>{i18n.language === "de"?i18n.t("en"):i18n.t("de")}</div>
					{!IsPortrait()?
					<div className="user_btn bgimg ia" onClick={this.user_btn_clicked}>
						{this.props.user != null && this.props.actualDomain!=null?
						<div>
						<span>{username}</span>
						<span className="company">{this.props.user.company}</span>	
						</div>
						:
						""
						}
					</div>
					:null}	
				</div>
				<UserDetails className={this.state.user_opened?" opened":""} id="user_drawer"></UserDetails>
        
				<Menu className={this.state.menu_opened?" opened":""} id="drawer" closed={this.drawerClosed}></Menu>
			
				<div className={"menu_btn bgimg ia"+(this.state.user_opened || this.state.menu_opened?" opened":"")} onClick={this.hamburger_btn_clicked}></div>	
				{IsPortrait() && this.props.headerData?
				<div className="title">
					<h1>{this.props.headerData.title.Get?this.props.headerData.title.Get():this.props.headerData.title}</h1>
					<h2>{this.props.headerData.subtitle.Get?this.props.headerData.subtitle.Get():this.props.headerData.subtitle}</h2>
				</div>
				:
				null
				}
				{IsPortrait()?
				
				<div className="mobilefooter">					
					<a href="/" className="f_user" onClick={this.user_btn_clicked}><div className="bgmask"></div></a>
					<NavLink className="f_products" to={CONST.pathes.product}><div className="bgmask"></div></NavLink>					
					<NavLink className="f_presentation"  to={CONST.pathes.presentations}><div className="bgmask"></div></NavLink>
				</div>
				:null}
				</div>
			</div>		
		);
	}
}

const mapStateToProps = state => ({
	headerData: GetHeader(state),
	user: getUser(state),
	actualDomain:actualDomain(state),
})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(Header)));