import {
	IMPORT_STATUS_GET_SUCCESS, IMPORT_PRODUCTS_SUCCESS, IMPORT_PRODUCTS_ERROR, IMPORT_PRODUCTIMAGES_SUCCESS, IMPORT_PRODUCTIMAGES_ERROR, IMPORT_GROUPTIMAGES_SUCCESS, IMPORT_GROUPTIMAGES_ERROR
} from './../actions/product';

import {initialState}  from 'initialState.js';

export function importReducer(state = initialState, action) {

	switch(action.type) {
	case IMPORT_STATUS_GET_SUCCESS: 
		return {...state, isImporting: action.payload.status,  error:false, info: action.payload.details}
	case IMPORT_PRODUCTS_SUCCESS:
		return {...state, isImporting:false,  error:false, info: action.payload.details}
	case IMPORT_PRODUCTS_ERROR:			
			return {...state, isImporting:false,error:true,  info: action.info.data}		
	case IMPORT_PRODUCTIMAGES_SUCCESS:
		return {...state, isImporting:false,  error:false, info: action.payload.details}
	case IMPORT_PRODUCTIMAGES_ERROR:
			return {...state, isImporting:false, error:true, info: action.info.data}		
	case IMPORT_GROUPTIMAGES_SUCCESS:
		return {...state, isImporting:false, error:false, info: action.payload.details}
	case IMPORT_GROUPTIMAGES_ERROR:
			return {...state, isImporting:false, error:true, info: action.info.data}		
	default: 
		return state;
	}
}


export const GetStatus = state => {return{importing:state.import.isImporting, info:state.import.info}};
export const GetError = state => {return{error:state.import.error, info:state.import.info}};
