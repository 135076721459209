import {fetchProducts, fecthImportStatus, fetchSartImport, fetchSartImportProductImages, fetchSartImportGroupImages} from './../actions/product';
import {_doRequest, Endpoints} from './_api.js';

export function get(data) {
	return _doRequest(Endpoints.product_get, fetchProducts, data);	
}

export function getImportStatus(data) {
	return _doRequest(Endpoints.product_import_status, fecthImportStatus, data);	
}

export function startImport(data) {	
	return _doRequest(Endpoints.product_import, fetchSartImport, data);	
}

export function startImportProductImages(data) {
	return _doRequest(Endpoints.product_import_productimages, fetchSartImportProductImages, data);	
}

export function startImportGroupImages(data) {
	return _doRequest(Endpoints.product_import_groupimages, fetchSartImportGroupImages, data);	
}