
import {RESPONSE_TYPE} from './../api/_api.js';

export const PRODUCT_GET_PENDING = 'PRODUCT_GET_PENDING';
export const PRODUCT_GET_SUCCESS = 'PRODUCT_GET_SUCCESS';
export const PRODUCT_GET_ERROR = 'PRODUCT_GET_ERROR';

export const IMPORT_STATUS_GET_PENDING = 'IMPORT_STATUS_GET_PENDING';
export const IMPORT_STATUS_GET_SUCCESS = 'IMPORT_STATUS_GET_SUCCESS';
export const IMPORT_STATUS_GET_ERROR = 'IMPORT_STATUS_GET_ERROR';

export const IMPORT_PRODUCTS_PENDING = 'IMPORT_PRODUCTS_PENDING';
export const IMPORT_PRODUCTS_SUCCESS = 'IMPORT_PRODUCTS_SUCCESS';
export const IMPORT_PRODUCTS_ERROR = 'IMPORT_PRODUCTS_ERROR';

export const IMPORT_PRODUCTIMAGES_PENDING = 'IMPORT_PRODUCTIMAGES_PENDING';
export const IMPORT_PRODUCTIMAGES_SUCCESS = 'IMPORT_PRODUCTIMAGES_SUCCESS';
export const IMPORT_PRODUCTIMAGES_ERROR = 'IMPORT_PRODUCTIMAGES_ERROR';

export const IMPORT_GROUPTIMAGES_PENDING = 'IMPORT_GROUPTIMAGES_PENDING';
export const IMPORT_GROUPTIMAGES_SUCCESS = 'IMPORT_GROUPTIMAGES_SUCCESS';
export const IMPORT_GROUPTIMAGES_ERROR = 'IMPORT_GROUPTIMAGES_ERROR';




export function fetchSartImportProductImages(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
		return {type: IMPORT_PRODUCTIMAGES_PENDING, payload:null,info: info};
	else if(type === RESPONSE_TYPE.SUCCES)
		return {type: IMPORT_PRODUCTIMAGES_SUCCESS,info: info,	payload: payload};
	else if(type === RESPONSE_TYPE.ERROR)
		return {type: IMPORT_PRODUCTIMAGES_ERROR,payload: null,info: info};
}
export function fetchSartImportGroupImages(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
		return {type: IMPORT_GROUPTIMAGES_PENDING, payload:null,info: info};
	else if(type === RESPONSE_TYPE.SUCCES)
		return {type: IMPORT_GROUPTIMAGES_SUCCESS,info: info,	payload: payload};
	else if(type === RESPONSE_TYPE.ERROR)
		return {type: IMPORT_GROUPTIMAGES_ERROR,payload: null,info: info};
}
export function fetchSartImport(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
		return {type: IMPORT_PRODUCTS_PENDING, payload:null,info: info};
	else if(type === RESPONSE_TYPE.SUCCES)
		return {type: IMPORT_PRODUCTS_SUCCESS,info: info,	payload: payload};
	else if(type === RESPONSE_TYPE.ERROR)
		return {type: IMPORT_PRODUCTS_ERROR,payload: null,info: info};
}

export function fecthImportStatus(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: IMPORT_STATUS_GET_PENDING, payload:null,info: info};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{		
		return {
			type: IMPORT_STATUS_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		
		return {
			type: IMPORT_STATUS_GET_ERROR,			
			payload: null,
			info: info
		};
	}
	
}


export function fetchProducts(type, info, payload) {
	if(type === RESPONSE_TYPE.PENDING)
	{
		return {type: PRODUCT_GET_PENDING, payload:info};
	}
	else if(type === RESPONSE_TYPE.SUCCES)
	{
		return {
			type: PRODUCT_GET_SUCCESS,
			info: info,
			payload: payload
		};
	}
	else if(type === RESPONSE_TYPE.ERROR)
	{
		return {
			type: PRODUCT_GET_ERROR,
			info: info,
			payload: payload
		};
	}
}